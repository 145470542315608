import type { FC, ReactNode } from 'react';

import { isCurrentUser } from '@zen/ActivityFeed/helpers/isCurrentUser';
import type { ActivityFeedUser } from '@zen/ActivityFeed/types';
import useRole from '@zen/Auth/hooks/useRole';
import { Tooltip } from '@zen/DesignSystem';
import { Role } from '@zen/Networks/types';
import useAccount from '@zen/utils/hooks/useAccount';

import { getOrganisationName, getPermittedRoleLabel } from '../../helpers/partiesDetailsHelper';
import robotSrc from './robot.png';

interface Props {
  isZensei?: boolean;
  showByWhom?: boolean;
  user: ActivityFeedUser;
}

const UserDetails: FC<Props> = ({ user, showByWhom = false, isZensei = false }) => {
  const { userProfile } = useAccount();
  const currentUserRole = useRole();
  const { email, firstName, lastName, organisationName, assignedRoles } = user;

  const username: string = isCurrentUser(userProfile, email) ? 'Me' : `${firstName} ${lastName.charAt(0)}.`;
  const isCustomerViewingAgent: boolean = currentUserRole === Role.CUSTOMER_USER && assignedRoles[0] === Role.AGENT_FORWARDER;
  const roleLabel: string = getPermittedRoleLabel(assignedRoles[0], isCustomerViewingAgent);

  const renderOrganisationName = (): ReactNode => {
    const name: ReactNode = (
      <p className="text-sm truncate max-w-32 text-navy-base">{getOrganisationName(organisationName, isCustomerViewingAgent)}</p>
    );

    if (roleLabel) {
      return (
        <Tooltip tooltipContent={roleLabel}>
          <div className="text-sm truncate max-w-32 text-navy-base">{name}</div>
        </Tooltip>
      );
    }

    return name;
  };

  return (
    <div className="flex items-center text-base whitespace-pre-wrap" data-testid="user-details">
      {!isZensei && (
        <>
          {showByWhom && <span className="text-grey-base"> by </span>}
          <p className="font-bold truncate max-w-24">{username}</p>
          <div className="flex items-baseline">
            <p className="text-grey-base"> from </p>
            {renderOrganisationName()}
          </div>
        </>
      )}
      {isZensei && (
        <>
          <img alt="Zensei" className="w-5 h-5 mr-1" data-testid="zensei-logo" src={robotSrc} />
          {firstName} {lastName}
        </>
      )}
    </div>
  );
};

export default UserDetails;
