import type { FC } from 'react';

import type { Account } from '@zen/Accounts/graphql/types';
import FilterComponent from '@zen/Components/Filters';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import { useGetEntitledAccountsFiltersQuery, useGetLocationCompaniesQuery } from './graphql';
import { buildFilters } from './helpers';
import type { AccountLocationsFilterItem, AccountLocationsFilters } from './types';

interface Props {
  accountId: string;
  managedAccounts: boolean;
}

const Filters: FC<Props> = (props: Props) => {
  const { accountId, managedAccounts = false } = props;
  const { filters, setFilters } = useUrlFilters<AccountLocationsFilters>();
  const { data } = useGetLocationCompaniesQuery({ variables: { accountId } });
  const { data: entitledAccountsData } = useGetEntitledAccountsFiltersQuery({ variables: { accountId } });

  const accountFilters: Pick<Account, 'id' | 'tradingName'>[] = [];

  if (managedAccounts && entitledAccountsData && entitledAccountsData.entitledAccounts) {
    accountFilters.push({
      id: entitledAccountsData.entitledAccounts.account.id,
      tradingName: entitledAccountsData.entitledAccounts.account.tradingName
    });

    entitledAccountsData.entitledAccounts.businessUnits.forEach((businessUnit) => {
      accountFilters.push({
        id: businessUnit.id,
        tradingName: businessUnit.tradingName
      });
    });

    entitledAccountsData.entitledAccounts.managed.forEach((managed) => {
      accountFilters.push({
        id: managed.id,
        tradingName: managed.tradingName
      });
    });

    entitledAccountsData.entitledAccounts.unmanaged.forEach((unmanaged) => {
      accountFilters.push({
        id: unmanaged.id,
        tradingName: unmanaged.tradingName
      });
    });

    accountFilters.sort((account, nextAccount) => account.tradingName.localeCompare(nextAccount.tradingName));
  }

  const filterItems: AccountLocationsFilterItem[] = buildFilters(accountFilters, data?.accountCompanies || []);

  return <FilterComponent items={filterItems} onChange={setFilters} value={filters} />;
};

export default Filters;
