import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetTextMessagesContentQueryVariables = GraphQLTypes.Exact<{
  idsByTarget: Array<GraphQLTypes.TextMessagesInput> | GraphQLTypes.TextMessagesInput;
}>;

export type GetTextMessagesContentQueryResult = { __typename?: 'Query' } & {
  activityFeed: { __typename?: 'ActivityFeed' } & {
    textMessages?: GraphQLTypes.Maybe<
      Array<{ __typename?: 'TextMessage' } & Pick<GraphQLTypes.TextMessage, 'archivedAt' | 'content' | 'id'>>
    >;
  };
};

export const GetTextMessagesContentDocument = /* #__PURE__ */ gql`
  query getTextMessagesContent($idsByTarget: [TextMessagesInput!]!) {
    activityFeed {
      textMessages(idsByTarget: $idsByTarget) {
        archivedAt
        content
        id
      }
    }
  }
`;

/**
 * __useGetTextMessagesContentQuery__
 *
 * To run a query within a React component, call `useGetTextMessagesContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTextMessagesContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTextMessagesContentQuery({
 *   variables: {
 *      idsByTarget: // value for 'idsByTarget'
 *   },
 * });
 */
export function useGetTextMessagesContentQuery(
  baseOptions: Apollo.QueryHookOptions<GetTextMessagesContentQueryResult, GetTextMessagesContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetTextMessagesContentQueryResult, GetTextMessagesContentQueryVariables>(
    GetTextMessagesContentDocument,
    options
  );
}
export function useGetTextMessagesContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTextMessagesContentQueryResult, GetTextMessagesContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetTextMessagesContentQueryResult, GetTextMessagesContentQueryVariables>(
    GetTextMessagesContentDocument,
    options
  );
}
export type GetTextMessagesContentQueryHookResult = ReturnType<typeof useGetTextMessagesContentQuery>;
export type GetTextMessagesContentLazyQueryHookResult = ReturnType<typeof useGetTextMessagesContentLazyQuery>;
