import type { Account } from '../../types';
import { type AccountLocationsFilterItem, LocationSource, UsageContext } from './types';

const buildFilters = (accounts: Partial<Account>[], companies: string[]): AccountLocationsFilterItem[] => {
  const filters: AccountLocationsFilterItem[] = [
    {
      title: 'Company',
      key: 'companies',
      props: {
        options: companies.map((company) => ({
          value: company,
          label: company
        })),
        selectAllOption: true
      },
      componentType: 'multi-select'
    },
    {
      title: 'Location ownership',
      key: 'source',
      props: {
        options: [
          { value: LocationSource.MANAGED, label: 'Managed locations' },
          { value: LocationSource.NON_MANAGED, label: 'Connection locations' },
          { value: LocationSource.OWNED, label: 'My locations' }
        ],
        selectAllOption: true
      },
      componentType: 'multi-select'
    },
    {
      title: 'HQ addresses only',
      key: 'hqOnly',
      props: {
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' }
        ]
      },
      componentType: 'select'
    },
    {
      title: 'Include archived',
      key: 'includeArchived',
      props: {
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' }
        ]
      },
      componentType: 'select'
    },
    {
      title: 'Usage',
      key: 'usageContexts',
      props: {
        options: [
          { value: UsageContext.BOTH, label: 'Both' },
          { value: UsageContext.SHIP_FROM, label: 'Send shipments' },
          { value: UsageContext.SHIP_TO, label: 'Receive shipments' }
        ],
        selectAllOption: true
      },
      componentType: 'multi-select'
    }
  ];

  if (accounts.length > 0) {
    filters.unshift({
      title: 'Account',
      key: 'accountIds',
      props: {
        options: accounts.map((account) => ({
          value: account.id || '',
          label: account.tradingName || ''
        }))
      },
      componentType: 'multi-select'
    });
  }

  return filters;
};

export { buildFilters };
