import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import UpdateLegalInformation from '@zen/Accounts/AccountForms/UpdateLegalInformation';
import EditableContentContainer from '@zen/Components/EditableContentContainer';
import { Modal } from '@zen/DesignSystem';

import AccountAddress from '../../components/AccountAddress';
import AccountDetailsItem from '../../components/AccountDetailsItem';
import type { Account } from '../../types';

type LegalInformation = Account['legalInformation'];
interface Props extends LegalInformation {
  accountId: string;
}

const LegalDetails: FC<Props> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { companyRegistrationNumber, eoriNumber, registeredAddress, registeredName, vatIdentificationNumber, accountId } = props;

  const toggleModal = (): void => setIsModalOpen(!isModalOpen);

  const address: ReactNode = <AccountAddress {...registeredAddress} country={registeredAddress.country} />;

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={toggleModal} title="Update legal details">
        <UpdateLegalInformation
          initialValues={{
            accountId,
            companyRegistrationNumber,
            eoriNumber,
            registeredName,
            vatIdentificationNumber
          }}
          onCancel={toggleModal}
          onSuccess={toggleModal}
        />
      </Modal>
      <EditableContentContainer heading="Legal details" onEdit={toggleModal}>
        <AccountDetailsItem name="Registered name" value={registeredName} />
        <AccountDetailsItem name="Company registration number" value={companyRegistrationNumber} />
        <AccountDetailsItem name="VAT identification number" value={vatIdentificationNumber} />
        <AccountDetailsItem name="EORI number" value={eoriNumber} />
        <AccountDetailsItem name="Registered address" value={address} />
      </EditableContentContainer>
    </>
  );
};

export default LegalDetails;
