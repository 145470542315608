import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type NetworksUpdateMyOrganisationMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateMyOrganisationInput;
}>;

export type NetworksUpdateMyOrganisationMutationResult = { __typename?: 'Mutation' } & {
  networksUpdateMyOrganisation?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateMyOrganisationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      organisation?: GraphQLTypes.Maybe<{ __typename?: 'MyOrganisation' } & Pick<GraphQLTypes.MyOrganisation, 'id'>>;
    }
  >;
};

export const NetworksUpdateMyOrganisationDocument = /* #__PURE__ */ gql`
  mutation networksUpdateMyOrganisation($input: UpdateMyOrganisationInput!) {
    networksUpdateMyOrganisation(input: $input) {
      errors {
        message
        path
      }
      organisation {
        id
      }
    }
  }
`;

/**
 * __useNetworksUpdateMyOrganisationMutation__
 *
 * To run a mutation, you first call `useNetworksUpdateMyOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworksUpdateMyOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networksUpdateMyOrganisationMutation, { data, loading, error }] = useNetworksUpdateMyOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNetworksUpdateMyOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NetworksUpdateMyOrganisationMutationResult,
    NetworksUpdateMyOrganisationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<NetworksUpdateMyOrganisationMutationResult, NetworksUpdateMyOrganisationMutationVariables>(
    NetworksUpdateMyOrganisationDocument,
    options
  );
}
export type NetworksUpdateMyOrganisationMutationHookResult = ReturnType<typeof useNetworksUpdateMyOrganisationMutation>;
