import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrganisationLocationContactsQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  organisationId: GraphQLTypes.Scalars['String'];
  locationId: GraphQLTypes.Scalars['String'];
}>;

export type OrganisationLocationContactsQueryResult = { __typename?: 'Query' } & {
  organisationLocationContacts?: GraphQLTypes.Maybe<
    { __typename?: 'NetworksLocation' } & {
      contacts?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksContactInLocationConnection' } & Pick<
          GraphQLTypes.NetworksContactInLocationConnection,
          'totalCount'
        > & {
            nodes?: GraphQLTypes.Maybe<
              Array<
                GraphQLTypes.Maybe<
                  { __typename?: 'NetworksContactInLocation' } & Pick<
                    GraphQLTypes.NetworksContactInLocation,
                    'asMainContact' | 'email' | 'firstName' | 'id' | 'lastName' | 'main' | 'phoneNumber'
                  > & {
                      canSendResetPasswordInstructions: { __typename?: 'AuthorizationResult' } & Pick<
                        GraphQLTypes.AuthorizationResult,
                        'value'
                      >;
                      invitation?: GraphQLTypes.Maybe<
                        { __typename?: 'Invitation' } & Pick<GraphQLTypes.Invitation, 'acceptUrl' | 'status'> & {
                            canSend: { __typename?: 'AuthorizationResult' } & Pick<
                              GraphQLTypes.AuthorizationResult,
                              'message' | 'value'
                            > & {
                                reasons?: GraphQLTypes.Maybe<
                                  { __typename?: 'FailureReasons' } & Pick<
                                    GraphQLTypes.FailureReasons,
                                    'details' | 'fullMessages'
                                  >
                                >;
                              };
                          }
                      >;
                      locations: Array<
                        { __typename?: 'NetworksLocationInContact' } & {
                          address: { __typename?: 'LegacyAddress' } & Pick<GraphQLTypes.LegacyAddress, 'street'>;
                        }
                      >;
                    }
                >
              >
            >;
            pageInfo: { __typename: 'PageInfo' } & Pick<
              GraphQLTypes.PageInfo,
              'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
            >;
          }
      >;
    }
  >;
};

export const OrganisationLocationContactsQueryDocument = /* #__PURE__ */ gql`
  query organisationLocationContactsQuery(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $organisationId: String!
    $locationId: String!
  ) {
    organisationLocationContacts: location(organisationId: $organisationId, locationId: $locationId) {
      contacts(after: $after, before: $before, first: $first, last: $last) {
        nodes {
          asMainContact
          canSendResetPasswordInstructions {
            value
          }
          email
          firstName
          id
          invitation {
            acceptUrl
            canSend {
              message
              reasons {
                details
                fullMessages
              }
              value
            }
            status
          }
          lastName
          locations {
            address {
              street
            }
          }
          main
          phoneNumber
        }
        pageInfo {
          ...PageInfo
        }
        totalCount
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useOrganisationLocationContactsQuery__
 *
 * To run a query within a React component, call `useOrganisationLocationContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationLocationContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationLocationContactsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      organisationId: // value for 'organisationId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useOrganisationLocationContactsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganisationLocationContactsQueryResult, OrganisationLocationContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrganisationLocationContactsQueryResult, OrganisationLocationContactsQueryVariables>(
    OrganisationLocationContactsQueryDocument,
    options
  );
}
export function useOrganisationLocationContactsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganisationLocationContactsQueryResult, OrganisationLocationContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrganisationLocationContactsQueryResult, OrganisationLocationContactsQueryVariables>(
    OrganisationLocationContactsQueryDocument,
    options
  );
}
export type OrganisationLocationContactsQueryHookResult = ReturnType<typeof useOrganisationLocationContactsQuery>;
export type OrganisationLocationContactsQueryLazyQueryHookResult = ReturnType<
  typeof useOrganisationLocationContactsQueryLazyQuery
>;
