import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateManagedAccountMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateManagedAccountInput;
}>;

export type CreateManagedAccountMutationResult = { __typename?: 'Mutation' } & {
  createManagedAccount?: GraphQLTypes.Maybe<
    { __typename?: 'CreateManagedAccountPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const CreateManagedAccountDocument = /* #__PURE__ */ gql`
  mutation createManagedAccount($input: CreateManagedAccountInput!) {
    createManagedAccount(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useCreateManagedAccountMutation__
 *
 * To run a mutation, you first call `useCreateManagedAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagedAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagedAccountMutation, { data, loading, error }] = useCreateManagedAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManagedAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateManagedAccountMutationResult, CreateManagedAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateManagedAccountMutationResult, CreateManagedAccountMutationVariables>(
    CreateManagedAccountDocument,
    options
  );
}
export type CreateManagedAccountMutationHookResult = ReturnType<typeof useCreateManagedAccountMutation>;
