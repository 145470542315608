import pDebounce from 'p-debounce';
import { useEffect } from 'react';

import type { Option } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import { useAllAccountsLazyQuery, useAllAccountsQuery } from '../graphql/allAccounts.generated';
import type { Account } from '../graphql/types';
import { AccountStatus } from '../types';

type SearchableAccountOptionsArguments = {
  nestedBusinessUnits?: boolean;
  // TODO - IMPLEMENT QUERY FILTER TO TAKE ACCOUNT_IDS
  selectedValues?: string[];
};

export const useSearchableAccountOptions = (args: SearchableAccountOptionsArguments) => {
  const { selectedValues, nestedBusinessUnits = false } = args;
  const [fetchAccounts, { data, loading, error }] = useAllAccountsLazyQuery({ fetchPolicy: 'no-cache' });
  const { data: accountsSelected } = useAllAccountsQuery({
    variables: {
      accountsFiltersInput: {
        accountIds: selectedValues,
        status: [AccountStatus.ACTIVE]
      },
      nestedBusinessUnits
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (selectedValues) {
      fetchAccounts({
        variables: {
          accountsFiltersInput: {
            accountIds: selectedValues,
            status: [AccountStatus.ACTIVE]
          },
          nestedBusinessUnits
        }
      });
    } else {
      fetchAccounts({
        variables: {
          accountsFiltersInput: {
            searchQuery: '',
            status: [AccountStatus.ACTIVE]
          },
          nestedBusinessUnits
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (query: string): void => {
    if (query) {
      fetchAccounts({
        variables: {
          accountsFiltersInput: {
            status: [AccountStatus.ACTIVE],
            searchQuery: query
          },
          nestedBusinessUnits
        }
      });
    }
  };

  const debounceDelay: number = 300;
  const debouncedHandleInputChange = pDebounce(handleInputChange, debounceDelay);

  const createAccountOption = (account: Optional<Account>): Option<string> => ({
    label: account?.tradingName || '',
    value: account?.id || ''
  });

  const selectedOptions: Option<string>[] = accountsSelected?.accounts?.nodes?.map(createAccountOption) || [];

  const options: Option<string>[] = data?.accounts?.nodes?.map(createAccountOption) || [];

  return {
    options,
    loading,
    error,
    onSearch: debouncedHandleInputChange,
    selectedOptions
  };
};
