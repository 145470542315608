import cx from 'classnames';
import { kebabCase } from 'lodash';
import type { FC, ReactNode, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

import useTracking from '@zen/utils/hooks/useTracking';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import { extractToAndState } from '@zen/utils/routing';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';
import type { Modify } from '@zen/utils/typescript';

import Icon from '../../Icon';
import Tooltip from '../../Tooltip';
import Divider from '../Divider';
import type { MenuItemType } from '../types';

interface Props {
  item: Modify<MenuItemType, { onClick?: (e: SyntheticEvent) => void }>;
}

const MenuItem: FC<Props> = (props) => {
  const {
    className,
    disabled,
    disabledItemTooltip,
    icon,
    isActive,
    label,
    linkTo,
    onClick,
    addDivider = false,
    openInNewTab
  } = props.item;
  const iconClassNames: string = cx({ 'text-grey-dark': !disabled, 'text-grey-lighter': disabled }, 'mr-2');
  const { navigate } = useNavigationHistory();
  const { trackEvent } = useTracking();

  const itemContent: ReactNode = (
    <>
      {icon && <Icon className={iconClassNames} icon={icon} />}
      <div className="text-sm leading-normal" {...createTrackingLabelAttribute()}>
        {label}
      </div>
      {isActive && <Icon className="absolute text-azure-base right-4" icon="zicon-tick" />}
    </>
  );

  const renderItem = (): ReactNode => {
    const classNames: string = cx(
      {
        'text-grey-dark active:bg-azure-light hover:bg-azure-lightest cursor-pointer': !disabled,
        'text-grey-light cursor-not-allowed disabled': disabled
      },
      'flex py-1.5 leading-6 items-center pl-6 pr-14 font-normal my-1 relative',
      className
    );

    const handleClick = (event: SyntheticEvent): void => {
      if (disabled) {
        return;
      }
      const trackingEvent = createTrackingEvent('Design System/ContextMenu/MenuItem', 'click', event);

      trackEvent(trackingEvent);

      onClick?.(event);
    };

    if (linkTo && !disabled) {
      const target: string | undefined = openInNewTab ? '_blank' : undefined;

      if (openInNewTab) {
        return (
          <Link className={classNames} onClick={handleClick} target={target} {...extractToAndState(linkTo)}>
            {itemContent}
          </Link>
        );
      }

      const handleInternalLinkClick = (e: SyntheticEvent) => {
        handleClick(e);
        if (typeof linkTo === 'string') {
          navigate(linkTo);
        } else {
          navigate(linkTo);
        }
      };

      return (
        <a className={classNames} onClick={handleInternalLinkClick}>
          {itemContent}
        </a>
      );
    }

    return (
      <div className={classNames} onClick={handleClick}>
        {itemContent}
      </div>
    );
  };

  const renderMenuItem = (): ReactNode => {
    if (disabledItemTooltip) {
      return (
        <Tooltip placement="left" tooltipContent={disabledItemTooltip}>
          {renderItem()}
        </Tooltip>
      );
    }

    return renderItem();
  };

  const testId: string = typeof label === 'string' ? `${kebabCase(label)}-menu-item` : 'context-menu-item';
  const classNames: string = cx(
    {
      disabled
    },
    'cursor-pointer'
  );

  return (
    <>
      <div
        className={classNames}
        data-component="context-menu-item"
        data-testid={testId}
        {...createTrackingParentAttribute('context-menu-item')}
      >
        {renderMenuItem()}
      </div>
      {addDivider && <Divider />}
    </>
  );
};

export default MenuItem;
