import { CargoItemTypeEnum } from '@zen/Cargo/types';
import { highlightQuery } from '@zen/Components/Form/utils';
import { consolidationRoutes, orderRoutes, packingListsRoutes, shipmentRoutes } from '@zen/Routes';
import quotesRoutes from '@zen/Routes/quotes';
import type { IconName } from '@zen/Styleguide';
import { cargoTypeLabelMapping } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

import type {
  GlobalSearchAutosuggestItem,
  GlobalSearchMetadataBookingType,
  GlobalSearchMetadataPackingListType,
  GlobalSearchResult
} from './types';
import { GlobalSearchTypeEnum } from './types';

const iconMapping: Record<GlobalSearchTypeEnum, IconName> = {
  [GlobalSearchTypeEnum.PURCHASE_ORDER]: 'zicon-orders',
  [GlobalSearchTypeEnum.BOOKING]: 'zicon-shipment',
  [GlobalSearchTypeEnum.BOOKING_REQUEST]: 'zicon-booking-request',
  [GlobalSearchTypeEnum.BOOKING_QUOTE]: 'zicon-quotes',
  [GlobalSearchTypeEnum.PACKING_LIST]: 'zicon-packing-list',
  [GlobalSearchTypeEnum.CONSOLIDATED_SHIPMENT]: 'zicon-shipment'
};

const globalSearchLabelMapping: Record<GlobalSearchTypeEnum, string> = {
  [GlobalSearchTypeEnum.PURCHASE_ORDER]: 'Orders',
  [GlobalSearchTypeEnum.BOOKING]: 'Shipments',
  [GlobalSearchTypeEnum.BOOKING_REQUEST]: 'Booking requests',
  [GlobalSearchTypeEnum.BOOKING_QUOTE]: 'Quotes',
  [GlobalSearchTypeEnum.PACKING_LIST]: 'Packing lists',
  [GlobalSearchTypeEnum.CONSOLIDATED_SHIPMENT]: 'Consolidated shipments'
};

const getUrl = (item: GlobalSearchResult): string => {
  if (item.type === GlobalSearchTypeEnum.PURCHASE_ORDER) {
    return orderRoutes.orderItems.getUrl(item.id);
  }

  if (item.type === GlobalSearchTypeEnum.PACKING_LIST) {
    return packingListsRoutes.packingListIndex.getUrl(item.reference);
  }

  if (item.type === GlobalSearchTypeEnum.BOOKING_QUOTE) {
    return quotesRoutes.quoteDetailsPage.getUrl(item.id);
  }

  if (item.type === GlobalSearchTypeEnum.CONSOLIDATED_SHIPMENT) {
    return consolidationRoutes.consolidationShipments.getUrl(item.id);
  }

  return shipmentRoutes.shipmentDetailsPage.getUrl(item.id);
};

const getLabel = ({ reference, type, metadata = {} }: GlobalSearchResult): string => {
  if (type === GlobalSearchTypeEnum.PACKING_LIST) {
    const { cargoType, cargoSubType } = metadata as GlobalSearchMetadataPackingListType;
    const prefix: string = cargoType !== CargoItemTypeEnum.LOOSE_CARGO ? 'a ' : '';
    const cargoLabel: string = cargoSubType ? cargoTypeLabelMapping[cargoSubType] : '';

    return `${prefix}${cargoLabel} in ${reference}`;
  }

  return reference;
};

const mapItems = (item: GlobalSearchResult, query: string): GlobalSearchAutosuggestItem => {
  const { type, accountName, metadata } = item;
  const archived: Optional<boolean> = (metadata as GlobalSearchMetadataBookingType)?.archived;

  const label: string = archived ? `${getLabel(item)} - Archived` : getLabel(item);

  return {
    name: highlightQuery(label, query) as string,
    url: getUrl(item),
    icon: archived ? 'zicon-archive' : iconMapping[type],
    accountName,
    entityLabel: archived ? `Archived ${globalSearchLabelMapping[type]}` : globalSearchLabelMapping[type]
  };
};

export const prepareResults = (globalSearchResults: GlobalSearchResult[], query: string): GlobalSearchAutosuggestItem[] => {
  return globalSearchResults.map((item: GlobalSearchResult) => mapItems(item, query));
};
