import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateAccountDivisionMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateAccountDivisionInput;
}>;

export type CreateAccountDivisionMutationResult = { __typename?: 'Mutation' } & {
  createAccountDivision?: GraphQLTypes.Maybe<
    { __typename?: 'CreateAccountBusinessUnitPayload' } & Pick<GraphQLTypes.CreateAccountBusinessUnitPayload, 'accountId'> & {
        errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      }
  >;
};

export const CreateAccountDivisionDocument = /* #__PURE__ */ gql`
  mutation createAccountDivision($input: CreateAccountDivisionInput!) {
    createAccountDivision(input: $input) {
      accountId
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useCreateAccountDivisionMutation__
 *
 * To run a mutation, you first call `useCreateAccountDivisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountDivisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountDivisionMutation, { data, loading, error }] = useCreateAccountDivisionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountDivisionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAccountDivisionMutationResult, CreateAccountDivisionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateAccountDivisionMutationResult, CreateAccountDivisionMutationVariables>(
    CreateAccountDivisionDocument,
    options
  );
}
export type CreateAccountDivisionMutationHookResult = ReturnType<typeof useCreateAccountDivisionMutation>;
