import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetEntitledAccountsFiltersQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type GetEntitledAccountsFiltersQueryResult = { __typename?: 'Query' } & {
  entitledAccounts?: GraphQLTypes.Maybe<
    { __typename?: 'EntitledAccountsPayload' } & {
      account: { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName'>;
      businessUnits: Array<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName'>>;
      managed: Array<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName'>>;
      unmanaged: Array<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName'>>;
    }
  >;
};

export const GetEntitledAccountsFiltersDocument = /* #__PURE__ */ gql`
  query getEntitledAccountsFilters($accountId: String!) {
    entitledAccounts(accountId: $accountId) {
      account {
        id
        tradingName
      }
      businessUnits {
        id
        tradingName
      }
      managed {
        id
        tradingName
      }
      unmanaged {
        id
        tradingName
      }
    }
  }
`;

/**
 * __useGetEntitledAccountsFiltersQuery__
 *
 * To run a query within a React component, call `useGetEntitledAccountsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitledAccountsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntitledAccountsFiltersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetEntitledAccountsFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<GetEntitledAccountsFiltersQueryResult, GetEntitledAccountsFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetEntitledAccountsFiltersQueryResult, GetEntitledAccountsFiltersQueryVariables>(
    GetEntitledAccountsFiltersDocument,
    options
  );
}
export function useGetEntitledAccountsFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEntitledAccountsFiltersQueryResult, GetEntitledAccountsFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetEntitledAccountsFiltersQueryResult, GetEntitledAccountsFiltersQueryVariables>(
    GetEntitledAccountsFiltersDocument,
    options
  );
}
export type GetEntitledAccountsFiltersQueryHookResult = ReturnType<typeof useGetEntitledAccountsFiltersQuery>;
export type GetEntitledAccountsFiltersLazyQueryHookResult = ReturnType<typeof useGetEntitledAccountsFiltersLazyQuery>;
