import type { ReactElement, ReactNode } from 'react';
import { useRef } from 'react';
import type { DayButtonProps } from 'react-day-picker';

import Tooltip from '../../../Tooltip';
import { useDatePickerContext } from '../../DatePicker.context';
import { convertDateObjectIntoDateString, getTooltipContent } from '../../helpers';

const DayButton = (props: DayButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { day, modifiers, ...buttonProps } = props;
  const { tooltip } = useDatePickerContext();

  const tooltipContent: ReactNode | undefined = tooltip ? getTooltipContent(day.date, tooltip) : undefined;

  const renderDayButton = (): ReactElement => {
    return (
      <button
        ref={buttonRef}
        data-testid={`day-${convertDateObjectIntoDateString(day.date)}`}
        name="day"
        type="button"
        {...buttonProps}
      />
    );
  };

  const renderTooltip = (): ReactElement => {
    return <Tooltip tooltipContent={tooltipContent}>{renderDayButton()}</Tooltip>;
  };

  // see https://daypicker.dev/api/enumerations/DayFlag
  if (modifiers.hidden) {
    return <div />;
  }

  return tooltipContent ? renderTooltip() : renderDayButton();
};

export default DayButton;
