import { ActivityFeedItemTypeEnum, ActivityFeedTargetTypeEnum } from '@zen/ActivityFeed/types';

import type {
  GetPurchaseOrderReferencesQueryResult,
  GetTextMessagesContentQueryResult,
  GetTextMessagesContentQueryVariables
} from '../graphql';
import type { PurchaseOrder, TargetNotifications, TextMessagesInput } from '../types';
import { NotificationsTargetTypeEnum } from '../types';

type TextMessages = GetTextMessagesContentQueryResult['activityFeed']['textMessages'];
type PurchaseOrdersData = GetPurchaseOrderReferencesQueryResult['purchaseOrders']['nodes'];

export const filterByTextMessage =
  (textMessagesContent: { [key: string]: string }) =>
  (item: TargetNotifications): boolean => {
    const { itemType, itemId } = item.lastNotificationItem;

    return itemType === ActivityFeedItemTypeEnum.TEXT_MESSAGE && !textMessagesContent[itemId];
  };

export const getTextMessagesContent = (textMessages: TextMessages): { [key: string]: string } => {
  const displayTextMessages = {};

  // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
  textMessages.forEach((message) => {
    if (message && !message.archivedAt) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      displayTextMessages[message.id] = message.content;
    }
  });

  return displayTextMessages;
};

export const getTextMessageQueryVariables = (
  list: TargetNotifications[],
  textMessagesContent: { [key: string]: string }
): GetTextMessagesContentQueryVariables => {
  const idsByTarget: TextMessagesInput[] = [];
  const itemsToFetch: TargetNotifications[] = list.filter(filterByTextMessage(textMessagesContent));

  if (itemsToFetch) {
    itemsToFetch.forEach((item: TargetNotifications) => {
      const {
        targetId,
        targetType,
        lastNotificationItem: { itemId }
      } = item;

      if (!Object.values(ActivityFeedTargetTypeEnum).find((value: string) => value === targetType)) {
        throw new Error(`${targetType} value doesn't exists on type ActivityFeedTargetTypeEnum`);
      }

      const activityFeedTargetType: ActivityFeedTargetTypeEnum = targetType.toString() as ActivityFeedTargetTypeEnum;

      idsByTarget.push({ targetId, targetType: activityFeedTargetType, ids: [itemId] });
    });
  }

  return { idsByTarget };
};

export const filterByPurchaseOrderTarget =
  () =>
  (item: TargetNotifications): boolean => {
    return item.targetType === NotificationsTargetTypeEnum.PURCHASE_ORDER;
  };

export const getPurchaseOrderIds = (
  list: TargetNotifications[],
  purchaseOrderReferences: { [key: string]: string }
): string[] => {
  const purchaseOrderIds: string[] = [];

  list.filter(filterByPurchaseOrderTarget).forEach((notification: TargetNotifications): void => {
    if (!purchaseOrderReferences[notification.targetId]) {
      purchaseOrderIds.push(notification.targetId);
    }
  });

  return purchaseOrderIds;
};

export const getPurchaseOrderReferences = (purchaseOrdersData: PurchaseOrdersData): { [key: string]: string } => {
  const mappedPurchaseOrderReferences = {};

  // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
  purchaseOrdersData.forEach((orderData: PurchaseOrder): void => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    mappedPurchaseOrderReferences[orderData.id] = orderData.orderReferenceNumber;
  });

  return mappedPurchaseOrderReferences;
};
