import type { ReactNode } from 'react';

import TableLink from '@zen/Components/TableLink';
import type { TableColumn } from '@zen/DesignSystem';
import { Pill } from '@zen/DesignSystem';

import type { AccountLocationSummary } from '../types';
import AccountLocationsActions from './AccountLocationsActions';

interface ColumConfiguration {
  accountId: string;
  managedAccounts: boolean;
  pathname: string;
}

const getColumnsConfiguration = ({
  accountId,
  managedAccounts = false,
  pathname
}: ColumConfiguration): TableColumn<AccountLocationSummary>[] => {
  return [
    {
      key: 'name',
      title: 'Name',
      render: (_, { addressLabel, companyName, name, id: locationId }: AccountLocationSummary): ReactNode => {
        return (
          <div>
            <div>
              <TableLink linkTo={`${pathname}/${locationId}`}>{name || companyName}</TableLink>
            </div>

            <div className="overflow-hidden text-xs truncate text-grey-base">{addressLabel}</div>
          </div>
        );
      }
    },
    {
      key: 'isRegisteredLegalAddress',
      sortable: false,
      title: '',
      render: (_, { isRegisteredLegalAddress }: AccountLocationSummary): ReactNode => {
        if (!isRegisteredLegalAddress) {
          return null;
        }

        return (
          <Pill className="capitalize" type="pending">
            HQ
          </Pill>
        );
      },
      width: 80
    },
    {
      key: 'companyName',
      title: 'Linked to',
      render: (_, { account, companyName }: AccountLocationSummary): ReactNode => {
        if (managedAccounts) {
          return account.tradingName;
        }

        return companyName;
      }
    },
    {
      key: 'status',
      title: 'Status',
      sortable: false,
      render: (_, { isArchived }: AccountLocationSummary): ReactNode => {
        if (isArchived) {
          return <Pill type="problem">Archived</Pill>;
        }

        return <Pill type="success">Active</Pill>;
      }
    },
    {
      key: 'actions',
      fixed: 'right',
      render: (_, { companyName, id, isRegisteredLegalAddress, name, permissions }: AccountLocationSummary): ReactNode => {
        return (
          <AccountLocationsActions
            accountId={accountId}
            canArchiveLocation={!!permissions?.canArchive?.value}
            canEdit={!!permissions?.canEditDetails?.value}
            canUnarchiveLocation={!!permissions?.canUnarchive?.value}
            companyName={companyName ?? ''}
            isRegisteredLegalAddress={isRegisteredLegalAddress}
            locationId={id}
            locationName={name}
          />
        );
      },
      sortable: false
    }
  ];
};

export default getColumnsConfiguration;
