import type { Nullable } from '@zen/utils/typescript';

import type { GetAccountDetailsQueryResult } from '../AccountDetails/graphql';
import type { AccountStep } from './types';

export const isAccountDetailsStepCompleted = (accountDetails: { tradingName: string }): boolean => {
  return Boolean(accountDetails.tradingName);
};

export const isCompanyDetailsStepCompleted = (legalInformation: { registeredName: string }): boolean => {
  return !!legalInformation.registeredName;
};

export const isAccountStepDisabled = (accountStep: AccountStep, currentExpandedStep: Nullable<AccountStep>): boolean => {
  const orderedSteps: AccountStep[] = ['AccountDetails', 'CompanyDetails', 'ShippingLocation'];

  const accountStepIndex: number = orderedSteps.findIndex((section) => section === accountStep);
  const currentExpandedStepIndex: number = orderedSteps.findIndex((section) => section === currentExpandedStep);

  return !!(currentExpandedStep && currentExpandedStepIndex < accountStepIndex);
};

export const getInitiallyExpandedStep = ({ account, accountLocations }: GetAccountDetailsQueryResult): Nullable<AccountStep> => {
  if (!account) return null;

  const { tradingName, legalInformation } = account;

  const steps: { isCompleted: boolean; name: AccountStep }[] = [
    {
      name: 'AccountDetails',
      isCompleted: isAccountDetailsStepCompleted({ tradingName })
    },
    {
      name: 'CompanyDetails',
      isCompleted: isCompanyDetailsStepCompleted(legalInformation)
    },
    {
      name: 'ShippingLocation',
      isCompleted: !!accountLocations?.nodes?.[0]
    }
  ];

  return steps.find(({ isCompleted }) => !isCompleted)?.name || null;
};
