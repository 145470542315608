import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCustomerSidebarCountersQueryVariables = GraphQLTypes.Exact<{
  accountIds?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>> | GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>
  >;
  customerUuid?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  onlyManufacturerRequested?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  onlyReadyForApproval?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
}>;

export type GetCustomerSidebarCountersQueryResult = { __typename?: 'Query' } & {
  bookingRequestsCount: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'>;
  quotesCount: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'>;
};

export const GetCustomerSidebarCountersDocument = /* #__PURE__ */ gql`
  query getCustomerSidebarCounters(
    $accountIds: [String]
    $customerUuid: String
    $onlyManufacturerRequested: Boolean
    $onlyReadyForApproval: Boolean
  ) {
    bookingRequestsCount: bookings(
      customerUuid: $customerUuid
      onlyManufacturerRequested: $onlyManufacturerRequested
      onlyReadyForApproval: $onlyReadyForApproval
    ) {
      totalCount
    }
    quotesCount: bookings(onlyQuotes: true, customers: $accountIds) {
      totalCount
    }
  }
`;

/**
 * __useGetCustomerSidebarCountersQuery__
 *
 * To run a query within a React component, call `useGetCustomerSidebarCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerSidebarCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerSidebarCountersQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      customerUuid: // value for 'customerUuid'
 *      onlyManufacturerRequested: // value for 'onlyManufacturerRequested'
 *      onlyReadyForApproval: // value for 'onlyReadyForApproval'
 *   },
 * });
 */
export function useGetCustomerSidebarCountersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomerSidebarCountersQueryResult, GetCustomerSidebarCountersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCustomerSidebarCountersQueryResult, GetCustomerSidebarCountersQueryVariables>(
    GetCustomerSidebarCountersDocument,
    options
  );
}
export function useGetCustomerSidebarCountersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerSidebarCountersQueryResult, GetCustomerSidebarCountersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCustomerSidebarCountersQueryResult, GetCustomerSidebarCountersQueryVariables>(
    GetCustomerSidebarCountersDocument,
    options
  );
}
export type GetCustomerSidebarCountersQueryHookResult = ReturnType<typeof useGetCustomerSidebarCountersQuery>;
export type GetCustomerSidebarCountersLazyQueryHookResult = ReturnType<typeof useGetCustomerSidebarCountersLazyQuery>;
