import type { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import type { TableColumn } from '@zen/DesignSystem';
import { Button, Table } from '@zen/DesignSystem';
import type { SortInput } from '@zen/types';
import { SortingOrder } from '@zen/types';
import { usePagination } from '@zen/utils/hooks/pagination';

import type {
  GetAccountBusinessUnitsQueryResult,
  GetAccountBusinessUnitsQueryVariables
} from '../graphql/getAccountBusinessUnits.generated';
import { useGetAccountBusinessUnitsQuery } from '../graphql/getAccountBusinessUnits.generated';
import type { BusinessUnit } from '../types';
import getColumnsConfiguration from './businessUnitsTableConfiguration';
import CreateBusinessUnitModal from './CreateBusinessUnitModal';

interface Props {
  accountId: string;
  accountReferencePrefix?: string | null;
}

const initialOrder: SortInput = {
  direction: SortingOrder.ASC,
  field: 'tradingName'
};

const BusinessUnits: FC<Props> = ({ accountId, accountReferencePrefix }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [order, setOrder] = useState<SortInput>(initialOrder);
  const { check } = useGlobalPermissions();
  const canCreateBusinessUnits: boolean = check('accounts.canCreateBusinessUnits');
  const canEditBusinessUnits: boolean = check('accounts.canEditBusinessUnits');

  const { loading, error, nodes, paginationInfo, totalCount } = usePagination<
    GetAccountBusinessUnitsQueryResult,
    GetAccountBusinessUnitsQueryVariables,
    BusinessUnit
  >(useGetAccountBusinessUnitsQuery, 'accountBusinessUnits', { accountId, order }, 20, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const columns: TableColumn<BusinessUnit>[] = getColumnsConfiguration({ canEditBusinessUnits });

  const tableId: string = 'businessUnits';

  const renderBusinessUnitsTable = (businessUnits: BusinessUnit[]): ReactElement => (
    <>
      <CreateBusinessUnitModal
        accountId={accountId}
        isOpen={isModalOpen}
        onClose={toggleModal}
        referencePrefix={accountReferencePrefix}
      />
      <Table<BusinessUnit>
        actions={tableActionButton}
        columns={columns}
        data={businessUnits}
        emptyText="No business units"
        onOrderChange={setOrder}
        order={order}
        paginationInfo={paginationInfo}
        rowKey="id"
        tableId={tableId}
        title="Business units"
        totalCountConfig={{
          totalCount,
          entityName: 'business unit'
        }}
      />
    </>
  );

  const toggleModal = (): void => setIsModalOpen((isOpen: boolean) => !isOpen);

  const tableActionButton: ReactNode = canCreateBusinessUnits ? (
    <Button onClick={toggleModal} size="compact">
      Create division
    </Button>
  ) : undefined;

  return (
    <QueryHandler
      data={nodes}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonTableLoading columns={columns} rowCount={20} tableId={tableId} />}
      noResults={renderBusinessUnitsTable([])}
    >
      {(businessUnits: BusinessUnit[]) => {
        return <>{renderBusinessUnitsTable(businessUnits)}</>;
      }}
    </QueryHandler>
  );
};

export default BusinessUnits;
