import { Button, Popover } from '@zen/DesignSystem';

import OptionList from '../FilterComponents/components/OptionList';
import { hasSections } from '../helpers';
import type { FilterItemType, FilterSection } from '../types';

interface Props<T> {
  addNewFilter: (filter: FilterItemType<T>) => void;
  items: Array<FilterItemType<T>> | Array<FilterSection<FilterItemType<T>>>;
  openFilter: (key: keyof T) => void;
  selectedFilterKeys: (keyof T)[];
}

const FilterOptions = <T,>({ items, openFilter, addNewFilter, selectedFilterKeys }: Props<T>) => {
  const scrollToPill = (key: keyof T) => {
    document
      .querySelector(`[data-filter-key="${key as string}"]`)
      ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  };

  const popover = ({ close }: { close: () => void }) => {
    const createListItemType = (item: FilterItemType<T>) => {
      const isSelected: boolean = selectedFilterKeys.includes(item.key);

      const handleClick = (): void => {
        openFilter(item.key);
        scrollToPill(item.key);

        item.onClick?.();

        if (!isSelected) {
          addNewFilter(item);
        }

        close();
      };

      return {
        label: item.title,
        onClick: handleClick,
        disabled: item.disabled,
        icon: item.icon,
        value: item,
        isSelected
      };
    };

    const createList = () => {
      if (hasSections(items)) {
        return items.map((item) => ({
          ...item,
          items: item.items.map(createListItemType)
        }));
      }

      return [
        {
          items: items.map(createListItemType)
        }
      ];
    };

    return (
      <div data-testid="filter-options-popover">
        <OptionList list={createList()} searchable={true} />
      </div>
    );
  };

  return (
    <Popover placement="bottom-start" popover={popover} popoverClassNames="border border-solid border-grey-lighter">
      <Button iconLeft="zicon-filter" size="compact" variant="secondary">
        Filters
      </Button>
    </Popover>
  );
};

export default FilterOptions;
