import { get } from 'lodash';
import type { FC } from 'react';

import AccountFormWrapper from '@zen/Accounts/components/forms/AccountFormWrapper';
import LocationUsageRadioGroup from '@zen/Accounts/components/forms/LocationUsageRadioGroup';
import { FormInput, FormSelect } from '@zen/Components';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import type { EntitledAccountsPayload } from '@zen/graphql/types.generated';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { getEntitledManageableAccountOptions } from '../../AccountLocations/AddLocationModal/helpers';
import CompanyNameFields from '../../components/CompanyNameFields';
import { useGetEntitledAccountsQuery, useUpdateAccountLocationDetailsMutation } from '../../graphql';
import { prepareValues } from './helpers';
import { locationDetailsValidation } from './locationDetails.validation';
import type { LocationDetailsFormValues } from './types';

type Props = {
  accountId: string;
  accountName: string;
  initialValues: LocationDetailsFormValues;
  locationAccountId: string;
  locationId: string;
  onCancel?: () => void;
  onSuccess?: () => void;
};

const UpdateLocationDetails: FC<Props> = ({
  accountId,
  accountName,
  initialValues,
  locationAccountId,
  locationId,
  onCancel,
  onSuccess
}) => {
  const managedAccounts = useFeatureIsOn('managed-accounts');

  const [updateLocation] = useUpdateAccountLocationDetailsMutation({ refetchQueries: ['getAccountLocation'] });
  const { addError, addSuccess } = useNotification();

  const { data } = useGetEntitledAccountsQuery({
    variables: { accountId },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    onError: (err) => {
      addError(get(err, '[0].message', 'There was a problem fetching the data.'));
    }
  });

  const handleUpdateLocationDetails = async (formValues: LocationDetailsFormValues): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateLocation({
          variables: {
            input: prepareValues({ formValues, locationId, accountId, accountName })
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'There was a problem updating the location details.'));
      },
      onSuccess: () => addSuccess('Location details updated.')
    });
  };

  return (
    <AccountFormWrapper<LocationDetailsFormValues>
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={handleUpdateLocationDetails}
      onSuccess={onSuccess}
      validationSchema={locationDetailsValidation}
    >
      {() => {
        return (
          <>
            {!managedAccounts && <CompanyNameFields />}
            {managedAccounts && (
              <FormSelect
                className="mb-4"
                isSearchable={true}
                label="This location is owned by:"
                name="locationAccountId"
                options={getEntitledManageableAccountOptions(data?.entitledAccounts as EntitledAccountsPayload)}
                renderMenuInPortal={true}
              />
            )}
            <FormInput label="Location name" name="name" />
            <LocationUsageRadioGroup name="usage" noMargin={true} />
          </>
        );
      }}
    </AccountFormWrapper>
  );
};

export default UpdateLocationDetails;
export type { Props as UpdateLocationDetailsProps };
