import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { BusinessHoursFragmentDoc } from '../../graphql/fragments/BusinessHours.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type NetworksUpdateOrganisationLocationMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateOrganisationLocationInput;
}>;

export type NetworksUpdateOrganisationLocationMutationResult = { __typename?: 'Mutation' } & {
  networksUpdateOrganisationLocation?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateOrganisationLocationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      location?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksLocation' } & Pick<GraphQLTypes.NetworksLocation, 'id' | 'locationType' | 'name'> & {
            address: { __typename?: 'LegacyAddress' } & Pick<
              GraphQLTypes.LegacyAddress,
              'city' | 'countyOrState' | 'postalCodeOrZip' | 'street'
            > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>> };
            businessHours?: GraphQLTypes.Maybe<
              { __typename?: 'BusinessHours' } & {
                monday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
                tuesday?: GraphQLTypes.Maybe<
                  { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                >;
                wednesday?: GraphQLTypes.Maybe<
                  { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                >;
                thursday?: GraphQLTypes.Maybe<
                  { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                >;
                friday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
                saturday?: GraphQLTypes.Maybe<
                  { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                >;
                sunday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
              }
            >;
            geolocation?: GraphQLTypes.Maybe<
              { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
            >;
            organisation:
              | ({ __typename?: 'AgentOrganisation' } & Pick<GraphQLTypes.AgentOrganisation, 'id' | 'name'>)
              | ({ __typename?: 'ForwarderOrganisation' } & Pick<GraphQLTypes.ForwarderOrganisation, 'id' | 'name'>)
              | ({ __typename?: 'MyOrganisation' } & Pick<GraphQLTypes.MyOrganisation, 'id' | 'name'>)
              | ({ __typename?: 'SavedOrganisation' } & Pick<GraphQLTypes.SavedOrganisation, 'id' | 'name'>);
          }
      >;
      orgLoc?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
            label?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>>;
            organisation?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksOrgLocOrganisation' } & Pick<GraphQLTypes.NetworksOrgLocOrganisation, 'id' | 'name'>
            >;
          }
      >;
    }
  >;
};

export const NetworksUpdateOrganisationLocationDocument = /* #__PURE__ */ gql`
  mutation networksUpdateOrganisationLocation($input: UpdateOrganisationLocationInput!) {
    networksUpdateOrganisationLocation(input: $input) {
      errors {
        message
        path
      }
      location {
        address {
          city
          country {
            code
            name
          }
          countyOrState
          postalCodeOrZip
          street
        }
        businessHours {
          ...BusinessHours
        }
        geolocation {
          latitude
          longitude
        }
        id
        locationType
        name
        organisation {
          id
          name
        }
      }
      orgLoc {
        id
        label {
          long
        }
        organisation {
          id
          name
        }
      }
    }
  }
  ${BusinessHoursFragmentDoc}
`;

/**
 * __useNetworksUpdateOrganisationLocationMutation__
 *
 * To run a mutation, you first call `useNetworksUpdateOrganisationLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworksUpdateOrganisationLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networksUpdateOrganisationLocationMutation, { data, loading, error }] = useNetworksUpdateOrganisationLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNetworksUpdateOrganisationLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NetworksUpdateOrganisationLocationMutationResult,
    NetworksUpdateOrganisationLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    NetworksUpdateOrganisationLocationMutationResult,
    NetworksUpdateOrganisationLocationMutationVariables
  >(NetworksUpdateOrganisationLocationDocument, options);
}
export type NetworksUpdateOrganisationLocationMutationHookResult = ReturnType<
  typeof useNetworksUpdateOrganisationLocationMutation
>;
