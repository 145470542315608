import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type LiveGlobalSearchQueryVariables = GraphQLTypes.Exact<{
  query: GraphQLTypes.Scalars['String'];
}>;

export type LiveGlobalSearchQueryResult = { __typename?: 'Query' } & {
  liveViewsGlobalSearch: { __typename?: 'LiveViewsGlobalSearchResult' } & {
    searchResult: Array<
      { __typename?: 'SearchResult' } & Pick<GraphQLTypes.SearchResult, 'accountName' | 'description' | 'id' | 'type'> & {
          matchedFields: Array<
            { __typename?: 'MatchedField' } & Pick<GraphQLTypes.MatchedField, 'additionalInformation' | 'type' | 'value'>
          >;
        }
    >;
  };
};

export const LiveGlobalSearchDocument = /* #__PURE__ */ gql`
  query liveGlobalSearch($query: String!) {
    liveViewsGlobalSearch(searchTerm: $query) {
      searchResult {
        accountName
        description
        id
        matchedFields {
          additionalInformation
          type
          value
        }
        type
      }
    }
  }
`;

/**
 * __useLiveGlobalSearchQuery__
 *
 * To run a query within a React component, call `useLiveGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveGlobalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveGlobalSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useLiveGlobalSearchQuery(
  baseOptions: Apollo.QueryHookOptions<LiveGlobalSearchQueryResult, LiveGlobalSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<LiveGlobalSearchQueryResult, LiveGlobalSearchQueryVariables>(LiveGlobalSearchDocument, options);
}
export function useLiveGlobalSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LiveGlobalSearchQueryResult, LiveGlobalSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<LiveGlobalSearchQueryResult, LiveGlobalSearchQueryVariables>(LiveGlobalSearchDocument, options);
}
export type LiveGlobalSearchQueryHookResult = ReturnType<typeof useLiveGlobalSearchQuery>;
export type LiveGlobalSearchLazyQueryHookResult = ReturnType<typeof useLiveGlobalSearchLazyQuery>;
