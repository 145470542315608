import { type FC, useCallback, useMemo, useState } from 'react';

import BusinessUnitsSubList from '@zen/Accounts/AccountList/BusinessUnitsSubList';
import Search from '@zen/Components/Search';
import { Table } from '@zen/DesignSystem';
import { ConnectionSourceTargetEnum } from '@zen/graphql/types.generated';
import { usePagination } from '@zen/utils/hooks/pagination';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import type { GetAccountConnectionsQueryResult, GetAccountConnectionsQueryVariables } from '../graphql';
import { useGetAccountConnectionsQuery } from '../graphql';
import type { AccountConnectionsSummary } from '../types';
import AccountConnectionFilters from './AccountConnectionFilters';
import getColumnsConfiguration from './accountConnectionsTableConfiguration';
import AddConnectionButton from './AddConnectionButton';

const initialFilters = {
  connectionSourceTarget: ConnectionSourceTargetEnum.BOTH
};

interface Props {
  accountId: string;
}

const AccountConnections: FC<Props> = ({ accountId }) => {
  const { filters } = useUrlFilters(initialFilters);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { addSuccess } = useNotification();

  const { loading, nodes, paginationInfo, totalCount } = usePagination<
    GetAccountConnectionsQueryResult,
    GetAccountConnectionsQueryVariables,
    AccountConnectionsSummary
  >(
    useGetAccountConnectionsQuery,
    'accountConnections',
    { accountId, accountConnectionsFiltersInput: { searchQuery, ...filters } },
    20,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const onIdCopied = useCallback((): void => {
    addSuccess('Copied to clipboard');
  }, [addSuccess]);

  const columns = useMemo(() => {
    return getColumnsConfiguration(accountId, onIdCopied);
  }, [accountId, onIdCopied]);

  const data = nodes.map((item) => ({ ...item, nestedRowItems: item.account.businessUnits }));

  return (
    <Table<AccountConnectionsSummary>
      actions={<AddConnectionButton accountId={accountId} />}
      additionalActions={
        <div className="flex space-x-2">
          <Search
            collapsible={true}
            onClear={() => setSearchQuery('')}
            onSubmit={setSearchQuery}
            placeholder="Search connections..."
            size="compact"
            widthClassName="w-48"
          />
          <AccountConnectionFilters />
        </div>
      }
      columns={columns}
      data={data}
      emptyText="No connections"
      loading={loading}
      paginationInfo={paginationInfo}
      renderExpandedRow={(record, index) => {
        return (
          <BusinessUnitsSubList
            businessUnits={record.account?.businessUnits || []}
            canEditBusinessUnit={false}
            tableId={`accountDivisionList-${index}`}
          />
        );
      }}
      rowKey="account.id"
      tableId="accountConnections"
      title="Connections"
      totalCountConfig={{
        totalCount,
        entityName: 'connection'
      }}
    />
  );
};

export default AccountConnections;
