import type { FC } from 'react';
import { useState } from 'react';

import { IconButton } from '@zen/DesignSystem';

import UpdateBusinessUnitModal from '../UpdateBusinessUnitModal';

interface Props {
  id: string;
  name: string;
  referencePrefix?: string | null;
}

const EditBusinessUnitButton: FC<Props> = ({ id, name, referencePrefix }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = (): void => setIsModalOpen((isOpen: boolean) => !isOpen);

  return (
    <div className="text-center">
      <UpdateBusinessUnitModal
        accountId={id}
        isOpen={isModalOpen}
        name={name}
        onClose={toggleModal}
        referencePrefix={referencePrefix}
      />
      <IconButton icon="zicon-edit" onClick={toggleModal} size="medium" title="Edit" variant="ghost" />
    </div>
  );
};

export default EditBusinessUnitButton;
