import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrganisationDetailsQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
  id: GraphQLTypes.Scalars['String'];
}>;

export type OrganisationDetailsQueryResult = { __typename?: 'Query' } & {
  network?: GraphQLTypes.Maybe<
    { __typename?: 'Network' } & {
      organisations?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksOrganisationInterfaceConnection' } & {
          nodes?: GraphQLTypes.Maybe<
            Array<
              GraphQLTypes.Maybe<
                | ({ __typename?: 'AgentOrganisation' } & Pick<
                    GraphQLTypes.AgentOrganisation,
                    'assignedRoles' | 'id' | 'name' | 'type'
                  > & {
                      locations: Array<
                        { __typename?: 'NetworksLocation' } & Pick<
                          GraphQLTypes.NetworksLocation,
                          'id' | 'locationType' | 'name'
                        > & {
                            address: { __typename?: 'LegacyAddress' } & Pick<
                              GraphQLTypes.LegacyAddress,
                              'city' | 'countyOrState' | 'postalCodeOrZip' | 'street'
                            > & {
                                country?: GraphQLTypes.Maybe<
                                  { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>
                                >;
                              };
                            geolocation?: GraphQLTypes.Maybe<
                              { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
                            >;
                          }
                      >;
                    })
                | ({ __typename?: 'ForwarderOrganisation' } & Pick<
                    GraphQLTypes.ForwarderOrganisation,
                    'assignedRoles' | 'id' | 'name' | 'type'
                  > & {
                      locations: Array<
                        { __typename?: 'NetworksLocation' } & Pick<
                          GraphQLTypes.NetworksLocation,
                          'id' | 'locationType' | 'name'
                        > & {
                            address: { __typename?: 'LegacyAddress' } & Pick<
                              GraphQLTypes.LegacyAddress,
                              'city' | 'countyOrState' | 'postalCodeOrZip' | 'street'
                            > & {
                                country?: GraphQLTypes.Maybe<
                                  { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>
                                >;
                              };
                            geolocation?: GraphQLTypes.Maybe<
                              { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
                            >;
                          }
                      >;
                    })
                | ({ __typename?: 'MyOrganisation' } & Pick<
                    GraphQLTypes.MyOrganisation,
                    'assignedRoles' | 'id' | 'name' | 'type'
                  > & {
                      locations: Array<
                        { __typename?: 'NetworksLocation' } & Pick<
                          GraphQLTypes.NetworksLocation,
                          'id' | 'locationType' | 'name'
                        > & {
                            address: { __typename?: 'LegacyAddress' } & Pick<
                              GraphQLTypes.LegacyAddress,
                              'city' | 'countyOrState' | 'postalCodeOrZip' | 'street'
                            > & {
                                country?: GraphQLTypes.Maybe<
                                  { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>
                                >;
                              };
                            geolocation?: GraphQLTypes.Maybe<
                              { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
                            >;
                          }
                      >;
                    })
                | ({ __typename?: 'SavedOrganisation' } & Pick<
                    GraphQLTypes.SavedOrganisation,
                    'assignedRoles' | 'id' | 'name' | 'type'
                  > & {
                      locations: Array<
                        { __typename?: 'NetworksLocation' } & Pick<
                          GraphQLTypes.NetworksLocation,
                          'id' | 'locationType' | 'name'
                        > & {
                            address: { __typename?: 'LegacyAddress' } & Pick<
                              GraphQLTypes.LegacyAddress,
                              'city' | 'countyOrState' | 'postalCodeOrZip' | 'street'
                            > & {
                                country?: GraphQLTypes.Maybe<
                                  { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>
                                >;
                              };
                            geolocation?: GraphQLTypes.Maybe<
                              { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
                            >;
                          }
                      >;
                    })
              >
            >
          >;
        }
      >;
    }
  >;
};

export const OrganisationDetailsDocument = /* #__PURE__ */ gql`
  query organisationDetails($accountUuid: String!, $id: String!) {
    network(id: $accountUuid) {
      organisations(ids: [$id]) {
        nodes {
          assignedRoles
          id
          locations {
            address {
              city
              country {
                code
                name
              }
              countyOrState
              postalCodeOrZip
              street
            }
            geolocation {
              latitude
              longitude
            }
            id
            locationType
            name
          }
          name
          type
        }
      }
    }
  }
`;

/**
 * __useOrganisationDetailsQuery__
 *
 * To run a query within a React component, call `useOrganisationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationDetailsQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganisationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganisationDetailsQueryResult, OrganisationDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrganisationDetailsQueryResult, OrganisationDetailsQueryVariables>(OrganisationDetailsDocument, options);
}
export function useOrganisationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganisationDetailsQueryResult, OrganisationDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrganisationDetailsQueryResult, OrganisationDetailsQueryVariables>(
    OrganisationDetailsDocument,
    options
  );
}
export type OrganisationDetailsQueryHookResult = ReturnType<typeof useOrganisationDetailsQuery>;
export type OrganisationDetailsLazyQueryHookResult = ReturnType<typeof useOrganisationDetailsLazyQuery>;
