import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import Icon from '../Icon';
import Popover from '../Popover/Popover';
import type { PopoverPlacement, PopoverTriggerEvent } from '../Popover/types';

interface Props {
  children?: ReactNode;
  className?: string;
  compactView?: boolean;
  delayHide?: number;
  delayShow?: number;
  offset?: number;
  placement?: PopoverPlacement;
  renderInPortal?: boolean;
  showArrow?: boolean;
  tooltipContent: ReactNode;
  trigger?: PopoverTriggerEvent;
  triggerClassName?: string;
}

const Tooltip: FC<Props> = (props) => {
  const {
    children = <Icon className="text-azure-base" icon="zicon-help" />,
    className,
    compactView = false,
    delayHide = 50,
    delayShow = 50,
    offset,
    placement = 'top',
    renderInPortal = true,
    showArrow = true,
    tooltipContent,
    trigger = 'hover',
    triggerClassName
  } = props;

  const popoverClassNames: string = cx(
    {
      'p-2 max-w-sm': !compactView
    },
    'font-sans font-normal text-xs text-grey-dark whitespace-normal leading-tight',
    className,
    'max-w-max'
  );

  return (
    <Popover
      delayHide={delayHide}
      delayShow={delayShow}
      interactive={true}
      offset={offset}
      placement={placement}
      popover={tooltipContent}
      popoverClassNames={popoverClassNames}
      renderInPortal={renderInPortal}
      showArrow={showArrow}
      trigger={trigger}
      triggerClassName={triggerClassName}
      variant="dark"
    >
      {children}
    </Popover>
  );
};

export type { Props as TooltipProps };

export default Tooltip;
