import { useCallback, useEffect, useRef, useState } from 'react';

import type { Nullable } from '@zen/utils/typescript';

export enum ScrollGesture {
  STARTED,
  STOPPED
}

const useScrollGestures = (customCondition = () => true) => {
  const [gesture, setGesture] = useState<Nullable<ScrollGesture>>(null);
  const startFlag = 1;
  const scrollValue = useRef<number>(0);
  const stopped = useRef<any>(null);

  const resetGestures = useCallback(() => {
    window.clearTimeout(stopped.current);
    setGesture(null);
  }, []);

  const startDetectScrollGesture = useCallback(() => {
    scrollValue.current++;
    const started = scrollValue.current === startFlag;

    if (started) {
      setGesture(ScrollGesture.STARTED);
    }

    window.clearTimeout(stopped.current);
    stopped.current = setTimeout(() => {
      scrollValue.current = 0;
      setGesture(ScrollGesture.STOPPED);
    }, 66);
  }, []);

  const scrollHandler = useCallback(() => {
    const shouldDetect = customCondition();

    if (!shouldDetect) {
      resetGestures();
    }

    if (shouldDetect) {
      startDetectScrollGesture();
    } else {
      setGesture(null);
    }
  }, [customCondition, resetGestures, startDetectScrollGesture]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  return {
    gesture
  };
};

export default useScrollGestures;
