import SkeletonLoading from '../../../SkeletonLoading/SkeletonLoading';

const LoadingMessage = () => (
  <div className="space-y-4">
    <SkeletonLoading height={20} width="w-1/3" />
    <SkeletonLoading height={20} width="w-1/3" />
    <SkeletonLoading height={20} width="w-1/3" />
  </div>
);

export default LoadingMessage;
