import * as Yup from 'yup';

import { addressValidation } from '@zen/Accounts/AccountForms/CreateAccountForm/createAccountForm.validation';
import { entityNameValidator } from '@zen/Accounts/validations';

const createManagedAccountValidation = Yup.object({
  companyName: entityNameValidator('Company name'),
  name: entityNameValidator('Location name'),
  usageContext: Yup.string().nullable().required('Please select location usage.'),
  businessHoursOption: Yup.string().nullable(),
  geolocation: Yup.object({
    isPinPlacementConfirmed: Yup.bool().oneOf([true], 'Please confirm that the map pin is in the correct location.')
  }),
  shippingLocationAddress: addressValidation
});

export { createManagedAccountValidation };
