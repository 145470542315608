import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type NetworkOrganisationContactsQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  networkId: GraphQLTypes.Scalars['String'];
  organisationId: GraphQLTypes.Scalars['String'];
}>;

export type NetworkOrganisationContactsQueryResult = { __typename?: 'Query' } & {
  networkOrganisationContacts?: GraphQLTypes.Maybe<
    { __typename?: 'Network' } & {
      organisations?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksOrganisationInterfaceConnection' } & {
          nodes?: GraphQLTypes.Maybe<
            Array<
              GraphQLTypes.Maybe<
                | ({ __typename?: 'AgentOrganisation' } & {
                    contacts: { __typename?: 'NetworksContactConnection' } & Pick<
                      GraphQLTypes.NetworksContactConnection,
                      'totalCount'
                    > & {
                        nodes?: GraphQLTypes.Maybe<
                          Array<
                            GraphQLTypes.Maybe<
                              { __typename?: 'NetworksContact' } & Pick<
                                GraphQLTypes.NetworksContact,
                                'email' | 'firstName' | 'id' | 'lastName' | 'main'
                              > & {
                                  invitation?: GraphQLTypes.Maybe<
                                    { __typename?: 'Invitation' } & Pick<GraphQLTypes.Invitation, 'acceptUrl' | 'status'> & {
                                        canSend: { __typename?: 'AuthorizationResult' } & Pick<
                                          GraphQLTypes.AuthorizationResult,
                                          'message' | 'value'
                                        > & {
                                            reasons?: GraphQLTypes.Maybe<
                                              { __typename?: 'FailureReasons' } & Pick<
                                                GraphQLTypes.FailureReasons,
                                                'details' | 'fullMessages'
                                              >
                                            >;
                                          };
                                      }
                                  >;
                                }
                            >
                          >
                        >;
                        pageInfo: { __typename: 'PageInfo' } & Pick<
                          GraphQLTypes.PageInfo,
                          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
                        >;
                      };
                  })
                | ({ __typename?: 'ForwarderOrganisation' } & {
                    contacts: { __typename?: 'NetworksContactConnection' } & Pick<
                      GraphQLTypes.NetworksContactConnection,
                      'totalCount'
                    > & {
                        nodes?: GraphQLTypes.Maybe<
                          Array<
                            GraphQLTypes.Maybe<
                              { __typename?: 'NetworksContact' } & Pick<
                                GraphQLTypes.NetworksContact,
                                'email' | 'firstName' | 'id' | 'lastName' | 'main'
                              > & {
                                  invitation?: GraphQLTypes.Maybe<
                                    { __typename?: 'Invitation' } & Pick<GraphQLTypes.Invitation, 'acceptUrl' | 'status'> & {
                                        canSend: { __typename?: 'AuthorizationResult' } & Pick<
                                          GraphQLTypes.AuthorizationResult,
                                          'message' | 'value'
                                        > & {
                                            reasons?: GraphQLTypes.Maybe<
                                              { __typename?: 'FailureReasons' } & Pick<
                                                GraphQLTypes.FailureReasons,
                                                'details' | 'fullMessages'
                                              >
                                            >;
                                          };
                                      }
                                  >;
                                }
                            >
                          >
                        >;
                        pageInfo: { __typename: 'PageInfo' } & Pick<
                          GraphQLTypes.PageInfo,
                          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
                        >;
                      };
                  })
                | ({ __typename?: 'MyOrganisation' } & {
                    contacts: { __typename?: 'NetworksContactConnection' } & Pick<
                      GraphQLTypes.NetworksContactConnection,
                      'totalCount'
                    > & {
                        nodes?: GraphQLTypes.Maybe<
                          Array<
                            GraphQLTypes.Maybe<
                              { __typename?: 'NetworksContact' } & Pick<
                                GraphQLTypes.NetworksContact,
                                'email' | 'firstName' | 'id' | 'lastName' | 'main'
                              > & {
                                  invitation?: GraphQLTypes.Maybe<
                                    { __typename?: 'Invitation' } & Pick<GraphQLTypes.Invitation, 'acceptUrl' | 'status'> & {
                                        canSend: { __typename?: 'AuthorizationResult' } & Pick<
                                          GraphQLTypes.AuthorizationResult,
                                          'message' | 'value'
                                        > & {
                                            reasons?: GraphQLTypes.Maybe<
                                              { __typename?: 'FailureReasons' } & Pick<
                                                GraphQLTypes.FailureReasons,
                                                'details' | 'fullMessages'
                                              >
                                            >;
                                          };
                                      }
                                  >;
                                }
                            >
                          >
                        >;
                        pageInfo: { __typename: 'PageInfo' } & Pick<
                          GraphQLTypes.PageInfo,
                          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
                        >;
                      };
                  })
                | ({ __typename?: 'SavedOrganisation' } & {
                    contacts: { __typename?: 'NetworksContactConnection' } & Pick<
                      GraphQLTypes.NetworksContactConnection,
                      'totalCount'
                    > & {
                        nodes?: GraphQLTypes.Maybe<
                          Array<
                            GraphQLTypes.Maybe<
                              { __typename?: 'NetworksContact' } & Pick<
                                GraphQLTypes.NetworksContact,
                                'email' | 'firstName' | 'id' | 'lastName' | 'main'
                              > & {
                                  invitation?: GraphQLTypes.Maybe<
                                    { __typename?: 'Invitation' } & Pick<GraphQLTypes.Invitation, 'acceptUrl' | 'status'> & {
                                        canSend: { __typename?: 'AuthorizationResult' } & Pick<
                                          GraphQLTypes.AuthorizationResult,
                                          'message' | 'value'
                                        > & {
                                            reasons?: GraphQLTypes.Maybe<
                                              { __typename?: 'FailureReasons' } & Pick<
                                                GraphQLTypes.FailureReasons,
                                                'details' | 'fullMessages'
                                              >
                                            >;
                                          };
                                      }
                                  >;
                                }
                            >
                          >
                        >;
                        pageInfo: { __typename: 'PageInfo' } & Pick<
                          GraphQLTypes.PageInfo,
                          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
                        >;
                      };
                  })
              >
            >
          >;
        }
      >;
    }
  >;
};

export const NetworkOrganisationContactsQueryDocument = /* #__PURE__ */ gql`
  query networkOrganisationContactsQuery(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $networkId: String!
    $organisationId: String!
  ) {
    networkOrganisationContacts: network(id: $networkId) {
      organisations(ids: [$organisationId]) {
        nodes {
          contacts(after: $after, before: $before, first: $first, last: $last) {
            nodes {
              email
              firstName
              id
              invitation {
                acceptUrl
                canSend {
                  message
                  reasons {
                    details
                    fullMessages
                  }
                  value
                }
                status
              }
              lastName
              main
            }
            pageInfo {
              ...PageInfo
            }
            totalCount
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useNetworkOrganisationContactsQuery__
 *
 * To run a query within a React component, call `useNetworkOrganisationContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkOrganisationContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkOrganisationContactsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      networkId: // value for 'networkId'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useNetworkOrganisationContactsQuery(
  baseOptions: Apollo.QueryHookOptions<NetworkOrganisationContactsQueryResult, NetworkOrganisationContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<NetworkOrganisationContactsQueryResult, NetworkOrganisationContactsQueryVariables>(
    NetworkOrganisationContactsQueryDocument,
    options
  );
}
export function useNetworkOrganisationContactsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NetworkOrganisationContactsQueryResult, NetworkOrganisationContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<NetworkOrganisationContactsQueryResult, NetworkOrganisationContactsQueryVariables>(
    NetworkOrganisationContactsQueryDocument,
    options
  );
}
export type NetworkOrganisationContactsQueryHookResult = ReturnType<typeof useNetworkOrganisationContactsQuery>;
export type NetworkOrganisationContactsQueryLazyQueryHookResult = ReturnType<typeof useNetworkOrganisationContactsQueryLazyQuery>;
