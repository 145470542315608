import { type FC, useState } from 'react';

import EditableContentContainer from '@zen/Components/EditableContentContainer';
import { Modal } from '@zen/DesignSystem';

import AccountDetailsItem from '../../components/AccountDetailsItem';
import { LocationLink } from '../../components/CompanyNameFields/helpers';
import { locationUsageLabelMapping } from '../helpers';
import type { UsageContext } from '../types';
import UpdateLocationDetails from '../UpdateLocationDetails';

interface Props {
  accountId: string;
  accountName: string;
  canEdit: boolean;
  companyName: string;
  locationAccountId: string;
  locationId: string;
  name: string;
  usage: UsageContext;
}

const LocationDetails: FC<Props> = ({
  accountId,
  accountName,
  canEdit,
  companyName,
  locationAccountId,
  locationId,
  name,
  usage
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMyCompany: boolean = accountName === companyName;

  const toggleModal = (): void => setIsModalOpen(!isModalOpen);

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={toggleModal} title="Update details">
        <UpdateLocationDetails
          accountId={accountId}
          accountName={accountName}
          initialValues={{
            companyName: isMyCompany ? '' : companyName,
            linkType: isMyCompany ? LocationLink.MY_COMPANY : LocationLink.OTHER_COMPANY,
            locationAccountId,
            name,
            usage
          }}
          locationAccountId={locationAccountId}
          locationId={locationId}
          onCancel={toggleModal}
          onSuccess={toggleModal}
        />
      </Modal>
      <EditableContentContainer heading="Details" onEdit={canEdit ? toggleModal : undefined}>
        <AccountDetailsItem fullRow={true} name="Linked to" value={companyName} />
        <AccountDetailsItem fullRow={true} name="Name" value={name} />
        <AccountDetailsItem fullRow={true} name="Usage" value={locationUsageLabelMapping[usage]} />
      </EditableContentContainer>
    </>
  );
};

export default LocationDetails;
