import type { RadioGroupOption } from '@zen/Components/RadioGroup';

import type { ConnectionFormType } from './types';

export const constructConnectionFormOptions = (
  canInviteConnections: boolean,
  canCreateManagedAccounts: boolean,
  canLinkAccounts: boolean
): RadioGroupOption<ConnectionFormType>[] => {
  const connectionFormOptions: RadioGroupOption<ConnectionFormType>[] = [];

  if (canInviteConnections)
    connectionFormOptions.push({
      label: 'Invite connection to join Zencargo',
      value: 'invite'
    });

  if (canCreateManagedAccounts)
    connectionFormOptions.push({
      label: 'Create and manage on behalf of connection',
      value: 'managed'
    });

  if (canLinkAccounts)
    connectionFormOptions.push({
      label: 'Link to an existing account',
      value: 'linked'
    });

  if (connectionFormOptions.length <= 1) {
    return [];
  }

  return connectionFormOptions;
};
