import type { MouseEvent } from 'react';

import { Icon } from '@zen/DesignSystem';

export interface Props {
  closeToast: (e: MouseEvent<HTMLElement>) => void;
}

const CloseButton = ({ closeToast }: Props) => {
  return <Icon className="mt-1 ml-6 text-xs text-white cursor-pointer" icon="zicon-close" onClick={closeToast} />;
};

export default CloseButton;
