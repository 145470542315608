import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ChargeTypesQueryVariables = GraphQLTypes.Exact<{
  type?: GraphQLTypes.InputMaybe<GraphQLTypes.ChargeTypeEnum>;
  basis?: GraphQLTypes.InputMaybe<GraphQLTypes.ChargeBasisEnum>;
}>;

export type ChargeTypesQueryResult = { __typename?: 'Query' } & {
  chargeTypes: { __typename?: 'ChargeTypeConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'ChargeType' } & Pick<
            GraphQLTypes.ChargeType,
            | 'chargeBasis'
            | 'chargeType'
            | 'fortyFiveFtHc'
            | 'fortyFtDv'
            | 'fortyFtHc'
            | 'hazardous'
            | 'id'
            | 'modeOfTransport'
            | 'name'
            | 'overweight'
            | 'reefer'
            | 'twentyFtDv'
            | 'twentyFtHc'
            | 'vatApplicable'
          >
        >
      >
    >;
  };
};

export const ChargeTypesDocument = /* #__PURE__ */ gql`
  query chargeTypes($type: ChargeTypeEnum, $basis: ChargeBasisEnum) {
    chargeTypes(paginated: false, type: $type, basis: $basis) {
      nodes {
        chargeBasis
        chargeType
        fortyFiveFtHc
        fortyFtDv
        fortyFtHc
        hazardous
        id
        modeOfTransport
        name
        overweight
        reefer
        twentyFtDv
        twentyFtHc
        vatApplicable
      }
    }
  }
`;

/**
 * __useChargeTypesQuery__
 *
 * To run a query within a React component, call `useChargeTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeTypesQuery({
 *   variables: {
 *      type: // value for 'type'
 *      basis: // value for 'basis'
 *   },
 * });
 */
export function useChargeTypesQuery(baseOptions?: Apollo.QueryHookOptions<ChargeTypesQueryResult, ChargeTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ChargeTypesQueryResult, ChargeTypesQueryVariables>(ChargeTypesDocument, options);
}
export function useChargeTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChargeTypesQueryResult, ChargeTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ChargeTypesQueryResult, ChargeTypesQueryVariables>(ChargeTypesDocument, options);
}
export type ChargeTypesQueryHookResult = ReturnType<typeof useChargeTypesQuery>;
export type ChargeTypesLazyQueryHookResult = ReturnType<typeof useChargeTypesLazyQuery>;
