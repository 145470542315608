import { omit } from 'lodash';

import { getDefaultLatLng } from '@zen/Accounts/AccountForms/helpers';
import type { Option, SelectOption } from '@zen/DesignSystem';
import type { EntitledAccountsPayload } from '@zen/graphql/types.generated';

import { LocationLink } from '../../components/CompanyNameFields/helpers';
import type { CreateAccountLocationFormValues, CreateAccountLocationInput } from './types';

const getEntitledManageableAccountOptions = (entitledAccountsPayload: EntitledAccountsPayload): SelectOption<string>[] => {
  const account = entitledAccountsPayload?.account;
  const businessUnits = entitledAccountsPayload?.businessUnits || [];
  const managed = entitledAccountsPayload?.managed || [];

  const accountOption = getAccountOption(account?.tradingName || '', account?.id || '');

  const businessUnitOptions = (): SelectOption<string> => {
    return {
      label: 'Business units',
      options: businessUnits?.map((businessUnit) => getAccountOption(businessUnit.tradingName, businessUnit.id))
    };
  };

  const managedAccountOptions = (): SelectOption<string> => {
    return {
      label: 'Managed connections',
      options: managed?.map((managedAccount) => getAccountOption(managedAccount.tradingName, managedAccount.id))
    };
  };

  return [accountOption, businessUnitOptions(), managedAccountOptions()];
};

const getAccountOption = (tradingName: string, id: string): Option<string> => {
  return {
    label: tradingName,
    value: id
  };
};

const prepareValues = (
  accountId: string,
  accountName: string,
  formValues: CreateAccountLocationFormValues
): CreateAccountLocationInput => {
  const {
    accountId: selectedAccountId,
    companyName,
    linkType,
    businessHoursOption,
    shippingLocationAddress,
    geolocation,
    ...rest
  } = formValues;

  return {
    accountId: selectedAccountId || accountId,
    location: {
      ...rest,
      geolocation: omit(geolocation, 'isPinPlacementConfirmed') || getDefaultLatLng(),
      address: shippingLocationAddress,
      companyName: linkType === LocationLink.MY_COMPANY ? accountName : companyName
    }
  };
};

export { getEntitledManageableAccountOptions, prepareValues };
