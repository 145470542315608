import * as Yup from 'yup';

import { entityNameValidator } from '@zen/Accounts/validations';

import { LocationLink } from '../../components/CompanyNameFields/helpers';

export const locationDetailsValidation = Yup.object({
  accountId: Yup.string().nullable(),
  companyName: Yup.string()
    .nullable()
    .when('linkType', {
      is: LocationLink.OTHER_COMPANY,
      then: () => entityNameValidator('Company name')
    }),
  name: entityNameValidator('Location name')
});
