import type { FC } from 'react';
import { useState } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import { Button, Dialog, Popover } from '@zen/DesignSystem';
import { Role } from '@zen/Networks/types';
import { newBookingRoutes } from '@zen/Routes';
import type { LocationDescriptor } from '@zen/types';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import OptionList from '../Filters/FilterComponents/components/OptionList';
import type { OptionListSection } from '../Filters/FilterComponents/components/OptionList/types';
import ChevronIcon from '../Icons/ChevronIcon';
import { getOrdersUrl } from './bookingButtonHelper';

interface Props {
  canRequestBooking?: boolean;
}

const BookingButton: FC<Props> = ({ canRequestBooking = false }) => {
  const role = useRole();
  const { referencePrefix } = useAccount();
  const { navigate } = useNavigationHistory();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);

  const toggleModal = (): void => setIsModalVisible(!isModalVisible);

  const handleClick = (url: LocationDescriptor): void => {
    if (!referencePrefix && role !== Role.AGENT_FORWARDER) {
      return toggleModal();
    }

    navigate(url);
  };

  const handleConfirm = (): void => {
    window.open('mailto:sales@zencargo.com');
    toggleModal();
  };

  const newBookingUrl: string = newBookingRoutes.newBookingIndex.getUrl();

  const options: Array<OptionListSection> =
    canRequestBooking && role !== Role.AGENT_FORWARDER
      ? [
          {
            title: 'Book for myself',
            items: [
              { label: 'Book a PO', onClick: () => handleClick(getOrdersUrl({ onlyOwnAccount: true })) },
              { label: 'Book without a PO', onClick: () => handleClick(`${newBookingUrl}?onlyOwnAccount=true`) }
            ]
          },
          {
            title: 'Book for someone I supply',
            items: [{ label: 'Fulfil a PO', onClick: () => navigate(getOrdersUrl({ onlyCurrentSupplier: true })) }]
          }
        ]
      : [
          {
            items: [
              {
                label: 'Book a PO',
                onClick: () => navigate(getOrdersUrl())
              },
              {
                label: 'Book without PO',
                onClick: () => navigate(newBookingUrl)
              }
            ]
          }
        ];

  return (
    <>
      <Popover
        onVisibilityChange={() => setIsPopoverVisible(!isPopoverVisible)}
        placement="bottom-start"
        popover={<OptionList list={options} />}
        popoverClassNames="border border-solid border-grey-lighter"
        renderInPortal={true}
      >
        <Button
          className="focus:outline-none"
          data-testid="booking-button"
          iconRight={<ChevronIcon className="text-lg" expanded={isPopoverVisible} size="small" />}
          variant="secondary"
        >
          New booking
        </Button>
      </Popover>
      <Dialog
        cancelLabel="Not now"
        confirmLabel="Contact Sales"
        header="Contact Sales to enable self-shipping"
        isOpen={isModalVisible}
        message="To initiate shipping your own orders, please contact our sales team. We require additional information to ensure smooth processing and support for your shipping needs."
        onClose={toggleModal}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default BookingButton;
