import cx from 'classnames';
import type { FC, ReactNode, SyntheticEvent } from 'react';
import { useRef } from 'react';

import Portal from '@zen/Components/Portal';
import { MODAL_OVERLAY_ZINDEX, MODAL_ZINDEX } from '@zen/Layout';
import useClickAway from '@zen/utils/hooks/useClickAway';

interface Props {
  children: ReactNode;
  className?: string;
  contentClassName?: string;
  isOpen: boolean;
  onClickAway?: (event: SyntheticEvent | MouseEvent | KeyboardEvent) => void;
}

const BaseModal: FC<Props> = (props) => {
  const { children, className, isOpen, onClickAway = () => false, contentClassName, ...rest } = props;
  const ref = useRef(null);

  const handleClickAway = (event: MouseEvent | KeyboardEvent): void => {
    onClickAway?.(event);
  };

  useClickAway(ref, handleClickAway);

  if (!isOpen) {
    return null;
  }

  const modalClassNames: string = cx('fixed inset-0 flex items-start mt-16 justify-center py-4', MODAL_ZINDEX, className);

  const modalContentClassNames: string = cx(
    'relative transform rounded shadow-popover bg-white flex flex-col max-h-full',
    contentClassName
  );

  const overlayClassnames: string = cx('fixed inset-0 bg-grey-base opacity-25', MODAL_OVERLAY_ZINDEX);

  return (
    <Portal>
      <div className={overlayClassnames} data-testid="base-modal-overlay" />
      <div className={modalClassNames} data-component="base-modal" data-testid="base-modal" {...rest}>
        <div ref={ref} className={modalContentClassNames}>
          {children}
        </div>
      </div>
    </Portal>
  );
};

export type { Props as ModalProps };

export default BaseModal;
