import { get } from 'lodash';
import { type FC, type ReactNode } from 'react';

import { Form, FormButtons, type FormInstance } from '@zen/Components';
import FormAccountMultiSelect from '@zen/Components/Form/FormAccountMultiSelect';
import { Button } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { AccountConnectionTypeEnum } from '../../types';
import { useLinkAccountsMutation } from './graphql';
import validationSchema from './linkAccountsForm.validation';
import type { LinkAccountsFormValues } from './types';

interface Props {
  accountId: string;
  closeModals: () => void;
}

const LinkAccountsForm: FC<Props> = ({ closeModals, accountId }) => {
  const [linkAccounts] = useLinkAccountsMutation({ refetchQueries: ['getAccountConnections'] });
  const { addError, addSuccess } = useNotification();

  const handleSubmit = async (values: LinkAccountsFormValues): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        linkAccounts({
          variables: {
            input: {
              targetAccountIds: values.accounts,
              sourceAccountId: accountId,
              connectionType: AccountConnectionTypeEnum.SUPPLIER
            }
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'Failed to link the selected accounts. Please try again.'));
      },
      onSuccess: () => {
        addSuccess('Accounts were successfully linked.');
        closeModals();
      }
    });
  };
  const renderFormButtons = ({ isSubmitting }: FormInstance<LinkAccountsFormValues>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Add connection">
      <Button data-testid="cancel-button" onClick={closeModals} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={{ accounts: [] }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {() => <FormAccountMultiSelect excludeAccounts={[accountId]} name="accounts" />}
    </Form>
  );
};

export default LinkAccountsForm;
