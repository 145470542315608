import type { FC } from 'react';

import AccountFormWrapper from '@zen/Accounts/components/forms/AccountFormWrapper';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import AccountDetailsFields from '../../components/forms/AccountDetailsFields';
import type { UpdateAccountProps } from '../../components/forms/types';
import { accountDetailsValidation } from '../CreateAccountForm/createAccountForm.validation';
import { useUpdateAccountMainDetailsMutation } from '../graphql';
import type { AccountDetails, UpdateAccountMainDetailsInput } from '../types';

type Props = UpdateAccountProps<AccountDetails>;

const UpdateAccountDetails: FC<Props> = ({ initialValues, onCancel, onSuccess }) => {
  const [updateMainDetails] = useUpdateAccountMainDetailsMutation({ refetchQueries: ['getAccountDetails'] });
  const { addError } = useNotification();

  const handleUpdateAccount = async (values: UpdateAccountMainDetailsInput): Promise<IOkOrErrorResult> => {
    const { accountId, referencePrefix, tradingName } = values;

    return performFormMutation({
      mutationFn: () =>
        updateMainDetails({
          variables: {
            input: {
              accountId,
              referencePrefix,
              tradingName
            }
          }
        }),
      onError: () => addError()
    });
  };

  return (
    <AccountFormWrapper<UpdateAccountMainDetailsInput>
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={handleUpdateAccount}
      onSuccess={onSuccess}
      validationSchema={accountDetailsValidation}
    >
      {() => <AccountDetailsFields isZencargoReferenceDisabled={!!initialValues.referencePrefix} />}
    </AccountFormWrapper>
  );
};

export default UpdateAccountDetails;
