import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GlobalSearchQueryVariables = GraphQLTypes.Exact<{
  query: GraphQLTypes.Scalars['String'];
}>;

export type GlobalSearchQueryResult = { __typename?: 'Query' } & {
  globalSearch?: GraphQLTypes.Maybe<
    Array<
      { __typename?: 'Search' } & Pick<GraphQLTypes.Search, 'accountName' | 'id' | 'reference' | 'type'> & {
          metadata?: GraphQLTypes.Maybe<
            | ({ __typename?: 'GlobalSearchMetadataBookingType' } & Pick<
                GraphQLTypes.GlobalSearchMetadataBookingType,
                'archived'
              >)
            | { __typename?: 'GlobalSearchMetadataEmptyType' }
            | ({ __typename: 'GlobalSearchMetadataPackingListType' } & Pick<
                GraphQLTypes.GlobalSearchMetadataPackingListType,
                'cargoSubType' | 'cargoType'
              >)
          >;
        }
    >
  >;
};

export const GlobalSearchDocument = /* #__PURE__ */ gql`
  query globalSearch($query: String!) {
    globalSearch(searchTerm: $query) {
      accountName
      id
      metadata {
        ... on GlobalSearchMetadataPackingListType {
          __typename
          cargoSubType
          cargoType
        }
        ... on GlobalSearchMetadataBookingType {
          archived
        }
      }
      reference
      type
    }
  }
`;

/**
 * __useGlobalSearchQuery__
 *
 * To run a query within a React component, call `useGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGlobalSearchQuery(baseOptions: Apollo.QueryHookOptions<GlobalSearchQueryResult, GlobalSearchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GlobalSearchQueryResult, GlobalSearchQueryVariables>(GlobalSearchDocument, options);
}
export function useGlobalSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GlobalSearchQueryResult, GlobalSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GlobalSearchQueryResult, GlobalSearchQueryVariables>(GlobalSearchDocument, options);
}
export type GlobalSearchQueryHookResult = ReturnType<typeof useGlobalSearchQuery>;
export type GlobalSearchLazyQueryHookResult = ReturnType<typeof useGlobalSearchLazyQuery>;
