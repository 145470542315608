import type { FC, ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import type { LocationPermissions } from '@zen/Accounts/types';
import { checkPermission } from '@zen/Auth/authHelper';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { Banner } from '@zen/DesignSystem';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import type { Nullable } from '@zen/utils/typescript';

import { useGetAccountLocationQuery } from '../graphql';
import type { AccountLocationDetails } from '../types';
import AddressDetails from './AddressDetails';
import BusinessHoursDetails from './BusinessHoursDetails';
import { formatBusinessHours } from './helpers';
import LocationDetails from './LocationDetails';

type RouteParams = {
  locationId: string;
};

interface Props {
  accountId?: Nullable<string>;
  accountName?: string;
  backUrl: string;
}

const AccountLocation: FC<Props> = ({ accountId, accountName, backUrl }) => {
  const managedAccounts = useFeatureIsOn('managed-accounts');

  const { locationId } = useParams<RouteParams>() as RouteParams;

  const {
    data: locationData,
    loading,
    error
  } = useGetAccountLocationQuery({
    variables: { locationId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only'
  });

  return (
    <QueryHandler data={locationData?.accountLocation} error={!!error} isLoading={loading}>
      {(location: AccountLocationDetails) => {
        const {
          account,
          addressLabel,
          companyName,
          isArchived,
          name,
          address,
          businessHours,
          geolocation,
          permissions,
          usageContext
        } = location;

        const canEdit: boolean = checkPermission<LocationPermissions>(permissions, 'canEditDetails');
        const canEditAddress: boolean = checkPermission<LocationPermissions>(permissions, 'canEditAddress');
        const canEditBusinessHours: boolean = checkPermission<LocationPermissions>(permissions, 'canEditBusinessHours');

        const renderLocationDetails = (): ReactNode => {
          return (
            <LocationDetails
              accountId={accountId || ''}
              accountName={accountName || ''}
              canEdit={canEdit}
              companyName={managedAccounts ? (account?.tradingName ?? '') : (companyName ?? '')}
              locationAccountId={account?.id || ''}
              locationId={locationId}
              name={name}
              usage={usageContext}
            />
          );
        };

        return (
          <Page defaultBackUrl={backUrl} title={name || addressLabel} width="wide">
            <div className="grid grid-cols-8 gap-8 xl:gap-6 xl:grid-cols-12">
              {isArchived && (
                <div className="col-span-8">
                  <Banner message="This location is archived and cannot be edited. Unarchive to make changes." type="warning" />
                </div>
              )}
              {renderLocationDetails()}
              <AddressDetails
                address={address}
                canEdit={canEditAddress}
                geolocation={geolocation}
                isArchived={isArchived}
                locationId={locationId}
              />
              <BusinessHoursDetails
                businessHours={formatBusinessHours(businessHours)}
                canEdit={canEditBusinessHours && !isArchived}
                isArchived={isArchived}
                locationId={locationId}
              />
            </div>
          </Page>
        );
      }}
    </QueryHandler>
  );
};

export default AccountLocation;
