import type { FC } from 'react';
import { useEffect, useState } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import RadioGroup from '@zen/Components/RadioGroup';
import { Button, Modal } from '@zen/DesignSystem';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import type { Nullable } from '@zen/utils/typescript';

import CreateManagedAccountForm from '../CreateManagedAccountForm';
import DuplicatedAccountsForm from '../DuplicatedAccountsForm';
import InviteCompanyConnectionForm from '../InviteCompanyConnectionForm';
import LinkAccountsForm from '../LinkAccountsForm';
import { constructConnectionFormOptions } from './helpers';
import { useAddConnections } from './hooks';
import type { ConnectionFormType, MatchedCompanies } from './types';

interface Props {
  accountId: string;
}

const AddConnectionButton: FC<Props> = ({ accountId }) => {
  const managedAccounts = useFeatureIsOn('managed-accounts');

  const [isAddConnectionModalOpen, setIsAddConnectionModalOpen] = useState(false);
  const [isDuplicatedAccountsModalOpen, setIsDuplicatedAccountsModalOpen] = useState(false);
  const [matchedCompanies, setMatchedCompanies] = useState<Nullable<MatchedCompanies>>(null);
  const [formType, setFormType] = useState<ConnectionFormType>('invite');
  const { check } = useGlobalPermissions();

  const canInviteConnections = check('accounts.canInviteConnections');
  const canCreateManagedAccounts = check('accounts.canCreateManagedAccounts');
  const canLinkAccounts = check('accounts.canLinkConnections');

  useEffect(() => {
    if (managedAccounts && !canInviteConnections && canCreateManagedAccounts && !canLinkAccounts) setFormType('managed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModals = (): void => {
    closeAddConnectionModal();
    closeDuplicatedAccountsModal();
    setMatchedCompanies(null);
  };

  const { createManagedAccountConnection, handleAccountSelect, inviteAccountConnection } = useAddConnections(
    accountId,
    closeModals
  );

  const openAddConnectionModal = (): void => setIsAddConnectionModalOpen(true);
  const openDuplicatedAccountsModal = (): void => setIsDuplicatedAccountsModalOpen(true);
  const closeAddConnectionModal = (): void => setIsAddConnectionModalOpen(false);
  const closeDuplicatedAccountsModal = (): void => setIsDuplicatedAccountsModalOpen(false);

  const handleCompanyMatch = (values: MatchedCompanies): void => {
    setMatchedCompanies(values);
    openDuplicatedAccountsModal();
  };

  const handleFormTypeChange = (value: ConnectionFormType): void => {
    setFormType(value);
  };

  const renderForm = () => {
    switch (formType) {
      case 'invite':
        return (
          <InviteCompanyConnectionForm
            accountId={accountId}
            onCancel={closeAddConnectionModal}
            onCompanyMatch={handleCompanyMatch}
            onSubmit={inviteAccountConnection}
          />
        );

      case 'managed':
        return <CreateManagedAccountForm onCancel={closeAddConnectionModal} onSubmit={createManagedAccountConnection} />;
      case 'linked':
        return <LinkAccountsForm accountId={accountId} closeModals={closeModals} />;
    }
  };

  return (
    <>
      <Modal
        closeOnClickAway={false}
        data-testid="add-connection-modal"
        isOpen={isAddConnectionModalOpen}
        onClose={closeAddConnectionModal}
        title="Add connection"
      >
        {managedAccounts && (
          <>
            <div>How would you like to add this connection?</div>
            <RadioGroup
              className="col-span-8 my-4"
              name="linkType"
              onChange={(value: string) => handleFormTypeChange(value as ConnectionFormType)}
              options={constructConnectionFormOptions(canInviteConnections, canCreateManagedAccounts, canLinkAccounts)}
              radioAlignment="column"
              value={formType}
            />
          </>
        )}

        {renderForm()}
      </Modal>
      <Modal
        closeButtonVisible={false}
        closeOnClickAway={false}
        data-testid="duplicated-accounts-modal"
        isOpen={isDuplicatedAccountsModalOpen}
        onClose={closeDuplicatedAccountsModal}
        tagline="We think this company might already exist in our records. Please confirm it's not a duplicate or connect to an existing record instead."
        title="Confirm this is not a duplicate"
      >
        <DuplicatedAccountsForm
          companyName={matchedCompanies?.companyName || ''}
          duplicates={matchedCompanies?.duplicates || []}
          onCancel={closeDuplicatedAccountsModal}
          onSubmit={(accountSelectValues) => handleAccountSelect(accountSelectValues, matchedCompanies)}
        />
      </Modal>
      <Button onClick={openAddConnectionModal} size="compact">
        Add connection
      </Button>
    </>
  );
};

export default AddConnectionButton;
