import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { BusinessHoursFragmentDoc } from '../../graphql/fragments/BusinessHours.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type NetworksCreateOrganisationMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateOrganisationInput;
}>;

export type NetworksCreateOrganisationMutationResult = { __typename?: 'Mutation' } & {
  networksCreateOrganisation?: GraphQLTypes.Maybe<
    { __typename?: 'CreateOrganisationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      organisation?: GraphQLTypes.Maybe<
        | ({ __typename?: 'AgentOrganisation' } & Pick<GraphQLTypes.AgentOrganisation, 'id'>)
        | ({ __typename?: 'ForwarderOrganisation' } & Pick<GraphQLTypes.ForwarderOrganisation, 'id'>)
        | ({ __typename?: 'MyOrganisation' } & Pick<GraphQLTypes.MyOrganisation, 'id'>)
        | ({ __typename?: 'SavedOrganisation' } & Pick<GraphQLTypes.SavedOrganisation, 'id'>)
      >;
      orgLoc?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id' | 'timeZone'> & {
            contact?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksOrgLocContact' } & Pick<
                GraphQLTypes.NetworksOrgLocContact,
                'email' | 'firstName' | 'id' | 'lastName' | 'phoneNumber'
              >
            >;
            label?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
            >;
            location?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksOrgLocLocation' } & Pick<GraphQLTypes.NetworksOrgLocLocation, 'id' | 'name'> & {
                  address?: GraphQLTypes.Maybe<
                    { __typename?: 'LegacyAddress' } & Pick<
                      GraphQLTypes.LegacyAddress,
                      'city' | 'countyOrState' | 'postalCodeOrZip' | 'street'
                    > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>> }
                  >;
                  businessHours?: GraphQLTypes.Maybe<
                    { __typename?: 'BusinessHours' } & {
                      monday?: GraphQLTypes.Maybe<
                        { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                      >;
                      tuesday?: GraphQLTypes.Maybe<
                        { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                      >;
                      wednesday?: GraphQLTypes.Maybe<
                        { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                      >;
                      thursday?: GraphQLTypes.Maybe<
                        { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                      >;
                      friday?: GraphQLTypes.Maybe<
                        { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                      >;
                      saturday?: GraphQLTypes.Maybe<
                        { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                      >;
                      sunday?: GraphQLTypes.Maybe<
                        { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                      >;
                    }
                  >;
                  geolocation?: GraphQLTypes.Maybe<
                    { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
                  >;
                }
            >;
            organisation?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksOrgLocOrganisation' } & Pick<GraphQLTypes.NetworksOrgLocOrganisation, 'id' | 'name'>
            >;
          }
      >;
    }
  >;
};

export const NetworksCreateOrganisationDocument = /* #__PURE__ */ gql`
  mutation networksCreateOrganisation($input: CreateOrganisationInput!) {
    networksCreateOrganisation(input: $input) {
      errors {
        message
        path
      }
      organisation {
        id
      }
      orgLoc {
        contact {
          email
          firstName
          id
          lastName
          phoneNumber
        }
        id
        label {
          long
          short
        }
        location {
          address {
            city
            country {
              code
              name
            }
            countyOrState
            postalCodeOrZip
            street
          }
          businessHours {
            ...BusinessHours
          }
          geolocation {
            latitude
            longitude
          }
          id
          name
        }
        organisation {
          id
          name
        }
        timeZone
      }
    }
  }
  ${BusinessHoursFragmentDoc}
`;

/**
 * __useNetworksCreateOrganisationMutation__
 *
 * To run a mutation, you first call `useNetworksCreateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworksCreateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networksCreateOrganisationMutation, { data, loading, error }] = useNetworksCreateOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNetworksCreateOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<NetworksCreateOrganisationMutationResult, NetworksCreateOrganisationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<NetworksCreateOrganisationMutationResult, NetworksCreateOrganisationMutationVariables>(
    NetworksCreateOrganisationDocument,
    options
  );
}
export type NetworksCreateOrganisationMutationHookResult = ReturnType<typeof useNetworksCreateOrganisationMutation>;
