import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type MyOrganisationContactsQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  networkId: GraphQLTypes.Scalars['String'];
}>;

export type MyOrganisationContactsQueryResult = { __typename?: 'Query' } & {
  myOrganisationContacts?: GraphQLTypes.Maybe<
    { __typename?: 'Network' } & {
      myOrganisation?: GraphQLTypes.Maybe<
        { __typename?: 'MyOrganisation' } & {
          contacts: { __typename?: 'NetworksContactConnection' } & Pick<GraphQLTypes.NetworksContactConnection, 'totalCount'> & {
              nodes?: GraphQLTypes.Maybe<
                Array<
                  GraphQLTypes.Maybe<
                    { __typename?: 'NetworksContact' } & Pick<
                      GraphQLTypes.NetworksContact,
                      'email' | 'firstName' | 'id' | 'lastName' | 'main' | 'phoneNumber'
                    > & {
                        invitation?: GraphQLTypes.Maybe<
                          { __typename?: 'Invitation' } & Pick<GraphQLTypes.Invitation, 'acceptUrl' | 'status'> & {
                              canSend: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'message' | 'value'
                              > & {
                                  reasons?: GraphQLTypes.Maybe<
                                    { __typename?: 'FailureReasons' } & Pick<
                                      GraphQLTypes.FailureReasons,
                                      'details' | 'fullMessages'
                                    >
                                  >;
                                };
                            }
                        >;
                        locations: Array<
                          { __typename?: 'NetworksLocationInContact' } & {
                            address: { __typename?: 'LegacyAddress' } & Pick<GraphQLTypes.LegacyAddress, 'street'>;
                          }
                        >;
                      }
                  >
                >
              >;
              pageInfo: { __typename: 'PageInfo' } & Pick<
                GraphQLTypes.PageInfo,
                'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
              >;
            };
        }
      >;
    }
  >;
};

export const MyOrganisationContactsQueryDocument = /* #__PURE__ */ gql`
  query myOrganisationContactsQuery($after: String, $before: String, $first: Int, $last: Int, $networkId: String!) {
    myOrganisationContacts: network(id: $networkId) {
      myOrganisation {
        contacts(after: $after, before: $before, first: $first, last: $last) {
          nodes {
            email
            firstName
            id
            invitation {
              acceptUrl
              canSend {
                message
                reasons {
                  details
                  fullMessages
                }
                value
              }
              status
            }
            lastName
            locations {
              address {
                street
              }
            }
            main
            phoneNumber
          }
          pageInfo {
            ...PageInfo
          }
          totalCount
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useMyOrganisationContactsQuery__
 *
 * To run a query within a React component, call `useMyOrganisationContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrganisationContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrganisationContactsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useMyOrganisationContactsQuery(
  baseOptions: Apollo.QueryHookOptions<MyOrganisationContactsQueryResult, MyOrganisationContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<MyOrganisationContactsQueryResult, MyOrganisationContactsQueryVariables>(
    MyOrganisationContactsQueryDocument,
    options
  );
}
export function useMyOrganisationContactsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyOrganisationContactsQueryResult, MyOrganisationContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<MyOrganisationContactsQueryResult, MyOrganisationContactsQueryVariables>(
    MyOrganisationContactsQueryDocument,
    options
  );
}
export type MyOrganisationContactsQueryHookResult = ReturnType<typeof useMyOrganisationContactsQuery>;
export type MyOrganisationContactsQueryLazyQueryHookResult = ReturnType<typeof useMyOrganisationContactsQueryLazyQuery>;
