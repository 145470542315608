import { uniqBy } from 'lodash';
import type { FC, ReactNode } from 'react';

import { useSearchableAccountOptions } from '@zen/Accounts/hooks/useSearchableAccountOptions';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { TextLink } from '@zen/DesignSystem';
import { accountsRoutes } from '@zen/Routes';
import type { Nullable, Optional } from '@zen/utils/typescript';

import FormMultiSelect from '../FormMultiSelect';

interface Props {
  error?: boolean;
  excludeAccounts?: string[];
  isLoading?: boolean;
  label?: string;
  name: string;
  noMargin?: boolean;
  onChange?: (value: Nullable<string[]>) => void;
  value?: Optional<string[]>;
  variant?: 'inline' | 'default';
}

const FormAccountMultiSelect: FC<Props> = (props) => {
  const { options, loading, onSearch, selectedOptions } = useSearchableAccountOptions({
    selectedValues: props.value ? props.value : undefined,
    nestedBusinessUnits: true
  });

  const { check } = useGlobalPermissions();
  const canCreateAccounts: boolean = check('platform.canCreateAccounts');

  const renderFooter = canCreateAccounts
    ? (): ReactNode => {
        return <TextLink linkTo={{ pathname: accountsRoutes.accountsCreate.getUrl() }}>Create new account</TextLink>;
      }
    : undefined;

  return (
    <FormMultiSelect
      {...props}
      dropdownFooterRenderer={renderFooter}
      isLoading={loading}
      onInputChange={onSearch}
      options={uniqBy([...options, ...selectedOptions], 'value')}
      placeholder="Search for an account..."
      renderMenuInPortal={true}
    />
  );
};

export default FormAccountMultiSelect;
export type { Props as FormAccountMultiSelectProps };
